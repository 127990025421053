import React, {useEffect, useState} from 'react';
import action_tracker from "../../tools/action_tracker";
import { Paper, Typography, List, ListItem, ListItemText } from '@mui/material';

export default function HistoryOfChanges({ entity_type, entity_id }) {
    const [userList, setUserList] = useState([])

    const localStorageGroups = localStorage.getItem('groups');
    const localStorageGroupsArr = localStorageGroups.split(',').map(Number);

    const allowedGroups = [8, 7, 1, 5, 2, 10]


    useEffect(() => {
        const fetchData = async () => {
          try {
            const result = await trackAction();
            console.log('Result from action_tracker:', result);
            setUserList(result)
          } catch (error) {
            console.error('Error while tracking action:', error);
          }
    };

    fetchData();
  }, []);

    const trackAction = async () => {
    try {
      const result = await action_tracker(entity_type, entity_id);
      return result;
    } catch (error) {
      throw error;
    }
  };

    const hasIntersection = localStorageGroupsArr.some(value => allowedGroups.includes(value));


    if(!hasIntersection) {
        return (
            null
        )
    }
   if (!userList || !userList.data || !Array.isArray(userList.data) || userList.data.length === 0) {
    return (
      <Paper elevation={3} style={{ padding: '16px', margin: '16px' }}>
        <Typography variant="h5" gutterBottom>
            So far, no one has worked on the entity
        </Typography>
      </Paper>
    );
  }

   console.log(userList)
  return (
    <Paper elevation={3} style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h5" gutterBottom>
        Who worked on the entity:
      </Typography>
      <List>
        {userList.data.map((item, index) => (

          <ListItem key={index}>
            <ListItemText
              primary={`User: ${item.user && item.user ? item.user : 'Unknow'}`}
              secondary={`Action: ${item.results.action}, Datetime: ${item.results.datetime}`}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
}