import {logs_url} from "../index";

const save_logs = async (entity_type, entity_id, action, display_name) => {
  console.log(12)
  try {
    const token = localStorage.getItem('token')
    const response = await fetch(logs_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${token}`,
      },
      body: JSON.stringify({
        entity_type,
        entity_id,
        display_name,
        action,
      }),
    });

    if (!response.ok) {
      throw new Error('Не удалось сохранить логи.');
    }

    const data = await response.json();
    console.log('Логи успешно сохранены:', data);
  } catch (error) {
    console.error('Ошибка при сохранении логов:', error);
  }
};

export default save_logs;
