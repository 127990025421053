import * as React from "react";
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {base_url} from "../../index";
import Grid from "@mui/material/Grid";
import {Button, CircularProgress, TextField} from "@mui/material";
import Alerts from "../Alerts/Alerts";


export default function Stadium() {
    const [stadiumData, setStadiumData] = useState(null);
    const [editedStadiumData, setEditedStadiumData] = useState(null);
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);

    let { id } = useParams();

    useEffect(() => {
    const fetchStadiumData = async () => {
      try {
        const response = await fetch(base_url + 'field/' + id);
        const data = await response.json();
        setStadiumData(data);
        setEditedStadiumData(data);

      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };

    fetchStadiumData();
  }, []);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        try {
            setLoading(true)
            let body = {
                  "title": editedStadiumData.title,
                  "short_title": editedStadiumData.short_title,
                  "dimension_length": editedStadiumData.dimension_length,
                  "dimension_width": editedStadiumData.dimension_width,
                }

          const response = await fetch(base_url + 'field/' + id + '/', {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                body
            ),
          });

          if (response.status === 200) {
              await setAnswer('success')
              await setMessage(response)
          } else {
            await setAnswer('error')
              await setMessage(response)
          }
        } catch (error) {
          console.error('Error updating field data:', error);
        }
        finally {
            setLoading(false)
        }
      };

    const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setEditedStadiumData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

    return(
        <div>
            <Grid container spacing={2} style={{marginTop: 35}}>
                <Grid item xs={6}>
                    {stadiumData && (
                    <form onSubmit={handleFormSubmit}>
                      <TextField
                        label="Title"
                        name="title"
                        fullWidth
                        value={editedStadiumData.title}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                      <TextField
                        label="Short Title"
                        name="short_title"
                        fullWidth
                        value={editedStadiumData.short_title}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                        <TextField
                        label="Dimension Length"
                        name="dimension_length"
                        fullWidth
                        value={editedStadiumData.dimension_length}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                        <TextField
                         label="Dimension Width"
                        name="dimension_width"
                        fullWidth
                        value={editedStadiumData.dimension_width}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />
                          <Button type="submit" variant="contained" color="primary">
                                Save
                      </Button>

                             {
                                                loading ?

                                                    <CircularProgress />
                                                    :
                                                        answer !== false ?
                                                    <Alerts success={answer} type={'Field'} message={message} action={'updated'}/>
                                                    :
                                                    <div></div>
                                            }

                    </form>
                        )}


                </Grid>
            </Grid>
        </div>
    )
}
