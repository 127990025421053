import Grid from "@mui/material/Grid";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CircularProgress, Divider,
    FormLabel,
    TablePagination,
    TextField
} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {json, Link} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import {base_url, opta_url, base_url_long, media_url} from "../../../index";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Alerts from "../../Alerts/Alerts";
import Paper from "@mui/material/Paper";


const SectionDivider = styled(Divider)({
  margin: '16px 0',
});

function Logo(props: { src: *, alt: string }) {
    return null;
}

export default function TeamsConnector() {
    const [params, setParams] = useState()
    const [result, setResult] = useState([])
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsTech, setSearchResultsTech] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [logo, setLogo] = useState()

    const [entity, setEntity] = useState()
    const [clarify, setClarify] = useState(false)
    const [team, setTeam] = useState()
    const [newData, setNewData] = useState({})

    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()

    const Logo = styled(Avatar)({
          width: 80,
          height: 80,
          marginBottom: 16,
        });

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };

    async function fetchNextTeam() {
        setClarify(false)
        setTeam()
        let obj_ss = {}
        let obj_opta = {}
        let opta_id = ''
        try {
            const fetchResponse = await fetch(
                `${base_url_long}random_entity/?entity=team&source_id=1`
            )
            const data = await fetchResponse.json()
            console.log(data)
            obj_ss = data
            opta_id = data.external_entity.entity_id
            await setLogo(media_url + data.logo)
            setAnswer(false)
            setMessage()


        }
        catch (e) {
            console.log(e)
        }
        try {
            const fetchResponse = await fetch(
                `${opta_url}team/hlivklxmlp1u1wc2mfm8elyhl?_rt=b&_fmt=json&ctst=${opta_id}`
            )
            const data = await fetchResponse.json()
            obj_opta = data
        }
        catch (e) {
            console.log(e)
        }
        setEntity({...obj_ss, ...obj_opta})

    }

     useEffect(() => {
        pageFetchSearch(page)
    }, [page])

    async function confirmCompliance() {
        try {
            const fetchResponse = await fetch(`${base_url_long}ftb_api_connector/team_entities/${entity.external_entity.id}/`, {
                method: "PATCH",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    "team": team ? team.id : entity.id,
                    "connect_status": "done",
                    "external_name": entity.contestant[0].name
                })
            })
            const data = await fetchResponse.json()
            console.log(data)
            if (data.id) {
                await setAnswer('success')
                await setMessage(data)
            } else {
                await setAnswer('error')
                await setMessage(data)
            }
            setClarify(false)
            setTeam()
            await fetchNextTeam()

        }
        catch (e) {
            console.log(e)
        }
    }

    async function pageFetchSearch() {
        if(typeof params !== 'number') {
            try {
                setLoading(true)
                const response = await fetch(`${base_url}team/?search=${params}&page=${page}`)
                const data = await response.json()
                setSearchResultsTech(data.count);
                setSearchResults(data.results);
                console.log(searchResults)
                console.log(searchResultsTech)
            }
            catch (e) {
                console.error(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

     const handleClarify = () => {
        setClarify(prevClarify => !prevClarify);
        if (clarify === true) {
            setTeam()
        }
        setParams(entity.name)
    };

    console.log(logo)

    return (
        <div>
            <p>

            </p>
            <Grid container spacing={2}>
                <Grid item xs={4}>

                    <div>
                        {
                            entity == undefined ? ' ' :
                                <>
                                <Card>
                                  <CardContent>

                                        <Box display="flex" alignItems="center" mb={2}>
                                          <Box ml={2}>

                                              <Logo src={`${logo}`} alt={`${entity.name} logo`} />

                                            <Typography variant="h5" component="div" gutterBottom>
                                                {team === undefined ? <> SS Team: {entity.name} </> : <>Upd SS Team: {team.name}</> }
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                              SS Short Name: {entity.short_name}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                              ID: {entity.id}
                                            </Typography>
                                              <SectionDivider />

                                        <Typography variant="h5" component="div" gutterBottom>
                                            Opta Name: <a style={{color: "black", textDecoration: "none"}} href={`https://www.transfermarkt.com/schnellsuche/ergebnis/schnellsuche?query=${entity.contestant[0].name}`} target="_blank">
                                            {entity.contestant[0].name}
                                            </a>
                                        </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                              Opta Short Name: {entity.contestant[0].shortName}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                              Opta ID: {entity.contestant[0].id}
                                            </Typography>
                                          </Box>
                                        </Box>

                                      </CardContent>
                                     <Box mt={4} display="flex" justifyContent="center" style={{marginBottom: 10}}>
                                    <Button variant="contained" color="primary" sx={{ marginRight: 2 }}
                                            onClick={() => confirmCompliance()}
                                    >
                                      Confirm compliance {team ? 'with the replacement of the command ' : ''}
                                    </Button>
                                    <Button variant="outlined" color="secondary" onClick={handleClarify}>
                                      {clarify ? 'Cancel Clarification' : 'To clarify'}
                                    </Button>
                                     </Box>
                                </Card>

                                       {
                                                    answer !== false ?
                                                        <Alerts success={answer} type={'team'} message={message} action={'matched'}/>
                                                        :
                                                        <div></div>
                                                }

                                    {
                                        team === undefined ? ' ' :

                                            <>

                                            </>
                                    }
                                </>
                        }
                         <FormControl>
                            <Button onClick={() => fetchNextTeam()}>
                                Next Team
                            </Button>
                    </FormControl>
                    </div>

                </Grid>
                <Grid item xs={8}>
                    {
                        clarify === false ? ' ' :

                            <>
                                <FormControl>
                                    <FormLabel>Enter name or Team</FormLabel>
                                    <TextField required defaultValue={entity.name}
                                               onChange={e => setParams(e.target.value)}
                                    >
                                    </TextField>
                                    <Button onClick={() => pageFetchSearch()}>
                                        Find Team
                                    </Button>
                                </FormControl>
                                {
                                    loading === true ? <CircularProgress/> : searchResults == false ? " "
                                        :
                                         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                              <Box sx={{ overflow: 'auto' }}>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell>Choose</TableCell>
                                                      <TableCell>Team ID</TableCell>
                                                      <TableCell align="left">Name</TableCell>
                                                      <TableCell align="right">Short Name</TableCell>
                                                      <TableCell align="right">Team Status</TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {
                                                       searchResults.map((team) => (
                                                            <TableRow key={team.id}>
                                                                <TableCell align="left"><Button onClick={() => setTeam(team)}>
                                                                    ➕
                                                                </Button></TableCell>
                                                                <TableCell align="left">{team.id}</TableCell>
                                                                <TableCell component="th" align="left" scope="row">
                                                                    <Link to={`/team/${team.id}`}> {team.name} </Link>
                                                                </TableCell>
                                                                <TableCell align="right">{team.short_name}</TableCell>
                                                                <TableCell
                                                                    align="right">{team.team_status_display}</TableCell>
                                                            </TableRow>
                                                    ))}
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                              <TablePagination
                                                component="div"
                                                count={searchResultsTech}
                                                page={page - 1}
                                                rowsPerPage={10}
                                                onPageChange={handleChangePage}
                                              />
                                            </Paper>
                                }
                            </>
                    }
                </Grid>
            </Grid>
        </div>
    )
}