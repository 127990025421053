import React, {useEffect, useState} from "react";
import {base_url, base_url_long} from "../../../index";
import Grid from "@mui/material/Grid";
import {
    Button, Checkbox,
    CircularProgress, FormControlLabel, List, ListItem, ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField
} from "@mui/material";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Alerts from "../../Alerts/Alerts";


export default function TeamsMerging() {
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsTech, setSearchResultsTech] = useState(0);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isInitialSearch, setIsInitialSearch] = useState(true);
    const [teamId, setTeamId] = useState('');
    const [teamsData, setTeamsData] = useState([]);

  const [afterMerge, setAfterMerge] = useState(null)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()


    const handlePotentialDoubles = async () => {
        try {
         setLoading(true)
        const response = await fetch(`${base_url_long}merge/teams/find_clones/?page=${page}`);
        const data = await response.json();
        setSearchResultsTech(data.count);
        setSearchResults(data.result);

        if (isInitialSearch) {
          setIsInitialSearch(false); // Set isInitialSearch to false after first successful search
        }
      }
     catch (e) {
       console.error(e)
     }
     finally {
        setLoading(false)
      }
    }

      const handleCheckboxChange = (player) => {
    const updatedSelectedTeams = [...selectedTeams];
    if (updatedSelectedTeams.includes(player)) {
      updatedSelectedTeams.splice(updatedSelectedTeams.indexOf(player), 1);
    } else {
      updatedSelectedTeams.push(player);
    }
    setSelectedTeams(updatedSelectedTeams);
  };

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
     };

    const handlePlayerIdChange = (e) => {
        setTeamId(e.target.value);
    };

    const handleUploadMergePlayers = async () => {
        try {
            setLoading(true)
          const response = await fetch(`${base_url_long}merge/teams/${teamId}/`);
          const data = await response.json();
          if(data.result) {
               await setTeamsData(data.result);
          }else {
              alert(data.detail)
          }

        } catch (error) {
          console.error('Error merging teams:', error);
        }
        finally {
            setLoading(false)
        }
  };

    const mergePlayers = async () => {
              try {
                  setLoading(true)
                const response = await fetch(`${base_url_long}merge/teams/draft/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    main: selectedTeams[0].id,
                    to_merge: selectedTeams.slice(1).map((player) => parseInt(player.id)),
                  }),
                });

                if (!response.ok) {
                  // Handle the error here, e.g., throw an error or show a message
                  throw new Error('Error merging players');
                }

                // Handle the response here, e.g., display a success message or update the UI
                const data = await response.json();
                await setAfterMerge(data)
                await setAnswer(false)
                await setMessage(undefined)
                console.log('Merge response:', data);
              } catch (error) {
                console.error('Merge players error:', error);
              }
              finally {
                  setLoading(false)
              }
};

    const confirmMergePlayers = async () => {
              try {
                const response = await fetch(`${base_url_long}merge/teams/process/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    main: selectedTeams[0].id,
                    to_merge: selectedTeams.slice(1).map((player) => parseInt(player.id)),
                  }),
                });

                if (!response.ok) {
                  // Handle the error here, e.g., throw an error or show a message
                  throw new Error('Error merging players');
                }

                // Handle the response here, e.g., display a success message or update the UI
                const data = await response.json();
                 if (data.result_merge.id) {
                    await setAnswer('success')
                    await setMessage(data)
                     await setAfterMerge(null)
                    await selectedTeams([])
                     await setTeamsData([])
                 }

                console.log('Merge response:', data);
              } catch (error) {
                console.error('Merge players error:', error);
                await setAnswer('success')
                await setMessage('')
              } finally {
              }
};


    useEffect(() => {

            handlePotentialDoubles(page);

    }, [page, isInitialSearch])

    return (
        <div style={{marginTop: 100}}>
      <Grid container spacing={2}>

        <Grid item xs={6}>

             <div>
                  <TextField
                    label="Enter Team ID"
                    value={teamId}
                    onChange={handlePlayerIdChange}
                  />
                  <Button variant="contained" color="primary" onClick={handleUploadMergePlayers}>
                    Find Team
                  </Button>
                 { teamsData ? (
                     <>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Checkbox</TableCell>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Short Name</TableCell>
                            <TableCell>Logo</TableCell>

                            {/* Add more columns as needed */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading === true ? <CircularProgress /> : teamsData === undefined ? ' ' : teamsData.map((player) => (
                            <TableRow key={player.id}>
                              <TableCell>
                                 <Checkbox
                        checked={selectedTeams.includes(player)}
                        onChange={() => handleCheckboxChange(player)}
                      />
                              </TableCell>
                              <TableCell>{player.id}</TableCell>
                              <TableCell>{player.name}</TableCell>
                              <TableCell>{player.short_name}</TableCell>
                              <TableCell>{player.birth_date}</TableCell>
                              {/* Add more cells for additional columns */}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </>
                  ) : null}
                </div>

        {/*    {*/}
        {/*        searchResults === undefined ? ' ' :*/}

        {/*    <TableContainer component={Paper}>*/}
        {/*  <Table>*/}
        {/*    <TableHead>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>ID</TableCell>*/}
        {/*        <TableCell>Full name</TableCell>*/}
        {/*        <TableCell>Nickname</TableCell>*/}
        {/*      </TableRow>*/}
        {/*    </TableHead>*/}
        {/*    <TableBody>*/}

        {/*      {loading ? (*/}
        {/*                <TableRow>*/}
        {/*                  <TableCell colSpan={4} align="center">*/}
        {/*                    <CircularProgress />*/}
        {/*                  </TableCell>*/}
        {/*                </TableRow>*/}
        {/*              ) : (searchResults.map((player) => (*/}
        {/*        <TableRow key={player.id}>*/}
        {/*          <TableCell>*/}
        {/*            <Link to={`/player/${player.id}`}>*/}
        {/*             {player.ids.join(', ')}*/}
        {/*            </Link>*/}
        {/*          </TableCell>*/}
        {/*          <TableCell>{player.name} {player.middle_name ?? ''} {player.surname ?? ''}</TableCell>*/}
        {/*          <TableCell>{player.nickname ?? ''} </TableCell>*/}



        {/*        </TableRow>*/}
        {/*      )))}*/}
        {/*    </TableBody>*/}
        {/*  </Table>*/}
        {/*  <TablePagination*/}
        {/*    component="div"*/}
        {/*    count={searchResultsTech}*/}
        {/*    page={page - 1}*/}
        {/*    rowsPerPage={10}*/}
        {/*    onPageChange={handleChangePage}*/}
        {/*  />*/}
        {/*</TableContainer>*/}
        {/*    }*/}
        </Grid>


        <Grid item xs={6}>
            <div>
                Based team:
                {selectedTeams.length === 0 ? 'No selected teams' : selectedTeams[0].id}
            </div>
             <div>
            Selected Teams: {selectedTeams === undefined ? ' ' : selectedTeams.slice(1).map((player) => player.id).join(', ')}
          </div>

            <Button onClick={() => mergePlayers()}>
                To Merge Teams
            </Button>

            <p>

            </p>

            {
                 loading === true ? <CircularProgress /> : afterMerge === null ? ' ' :
                    <div>


                    After merging several teams, we got the following result:
                        {
                            <div>
                                { afterMerge.after_merge === undefined ? ' ' :

                                    <Paper elevation={3} style={{padding: '20px', margin: '20px'}}>
                                        <Typography variant="h4" gutterBottom>
                                            Team Details
                                        </Typography>
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={`ID: ${afterMerge.after_merge.id}`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={`Name: ${afterMerge.after_merge.name}`}/>
                                            </ListItem> <
                                            ListItem>
                                                <ListItemText
                                                    primary={`Name: ${afterMerge.after_merge.short_name} `}/>
                                            </ListItem>
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={`Nationality: ${afterMerge.after_merge.nationality.join(', ')}`}/>*/}
                                            {/*</ListItem>*/}
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={`Position: ${afterMerge.after_merge.position.join(', ')}`}/>*/}
                                            {/*</ListItem>*/}
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText*/}
                                            {/*        primary={`Merged: ${afterMerge.after_merge.merged ? 'Yes' : 'No'}`}/>*/}
                                            {/*</ListItem>*/}
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={`New count of matches: ${afterMerge.main_matches_count + afterMerge.merge_matches_count}`}/>*/}
                                            {/*</ListItem>*/}


                                        </List>


                                    </Paper>
                                }
                            if you are sure, you can confirm the data merge

                                        <Button onClick={() => confirmMergePlayers()}>
                                            Confirm the data merge
                                        </Button>

                            </div>
                        }
                    </div>
            }
              {
                                      answer !== false ?
                                        <Alerts success={answer} type={'teams'} message={message} action={'merged'}/>
                                        :
                                        <div></div>
                                }

        </Grid>
      </Grid>
    </div>
    )
}