import React, { useState } from 'react';
import {TextField, Button, FormLabel, CircularProgress, TableContainer, Paper, TablePagination} from '@mui/material';
import {base_url} from "../../index";
import Alerts from "../Alerts/Alerts";
import FormControl from "@mui/material/FormControl";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Link} from "react-router-dom";

function Stadiums() {
    const [title, setTitle] = useState('')
    const [shortTitle, setShortTitle] = useState('')
    const [dimensionLength, setDimensionLength] = useState(null)
    const [dimensionWidth, setDimensionWidth] = useState(null)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);
    const [searchResultsTech, setSearchResultsTech] = useState(0);
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(1);
    const [params, setParams] = useState()

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${base_url}field/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "title": title,
            "short_title": shortTitle,
            "dimension_length": dimensionLength,
            "dimension_width": dimensionWidth
        })
      });

      if (response.ok) {
        console.log('Stadium created successfully');
      } else {
        console.error('Error creating stadium');
      }
    } catch (error) {
      console.error('Error creating stadium:', error);
    }
  };

  async function fetchSearch() {
        if(typeof params !== 'number') {
            try {
                setLoading(true)
                const response = await fetch(`${base_url}field/?search=${params}&page=${page}`)
                const data = await response.json()
                setSearchResultsTech(data.count);
                setSearchResults(data.results);
            }
            catch (e) {
                console.error(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

    const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Add 1 to match the current page number for API call
  };

  return (
    <div>

         <FormControl>
                <FormLabel>Enter name stadium</FormLabel>
                    <TextField required
                    onChange={e => setParams(e.target.value)}
                    >
                    </TextField>
                    <Button onClick={() => fetchSearch()}>
                        Find Stadium
                    </Button>
          </FormControl>
        {
                loading === true ? <CircularProgress /> :
                searchResults == false ? " "
                    :
                    <div>
                        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Short Title</TableCell>
                <TableCell>Dimension length</TableCell>
                <TableCell>Dimension width</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((stadium) => (
                <TableRow key={stadium.id}>

                  <TableCell>
                    <Link to={`/stadium/${stadium.id}`}>
                      {stadium.id}
                    </Link>
                  </TableCell>
                    <TableCell>
                        {stadium.title}
                    </TableCell>
                  <TableCell>
                      {stadium.short_title}
                  </TableCell>
                  <TableCell>
                      {
                          stadium.dimension_length
                      }
                  </TableCell>
                  <TableCell>
                      {
                          stadium.dimension_width
                      }
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
                    </div>
            }

      <h2>Create Stadium</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          name="title"
          onChange={event => setTitle(event.target.value)}
          required
        />
        <TextField
          label="Short Title"
          name="short_title"
          onChange={event => setShortTitle(event.target.value)}
        />
        <TextField
          label="Dimension Length"
          name="dimension_length"
          onChange={event => setDimensionLength(event.target.value)}
        />
        <TextField
          label="Dimension Width"
          name="dimension_width"
          onChange={event => setDimensionWidth(event.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Create Stadium
        </Button>
          {
               answer !== false ?
                                        <Alerts success={answer} type={'player'} message={message} action={'added'}/>
                                        :
                                        <div></div>
          }
      </form>
    </div>
  );
}

export default Stadiums;
