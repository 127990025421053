import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button, colors, FormLabel, Select, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Alerts from "../Alerts/Alerts";
import AddCompetitionForm from "./AddCompetitionForm";
import {base_url} from "../../index";
import {Link} from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

export default function SearchCompetitions() {
    const [params, setParams] = useState()
    const [result, setResult] = useState([])
    const [edit, setEdit] = useState(false)

    const [name, setName] = useState("")
    const [short_name, setShortName] = useState("")
    const [format, setFormat] = useState("")


    console.log(result)
    async function fetchSearch() {
        if(typeof params !== 'number') {
            const response = await fetch(base_url + 'competition/?search=' + params)
            const data = await response.json()
            await setResult(data)
            // const data = await response.json()
            // await setResult(data)
        }
    }

    const Change = async () => {
        const location = 'location' + "id"
        const settings = {
            method: "PATCH",
            headers: {
                "Content-type": "application/json",
                "Authorization": "token "
            },
            body: {
                "name": name,
                "short_name": short_name,
                "format": format,
            }
        }
        try {
            // const fetchResponse = await fetch(`http://${location}:9000/api/sensors/`, settings);
            // const data = await fetchResponse.json();
            await setName("success")
            // return data;

        }
        catch (e) {
            console.log(e)
            await setName("error")
        }


    }

  return (
      <div>
          <FormControl>
                <FormLabel>Enter NAME of competition</FormLabel>
                    <TextField required
                    onChange={e => setParams(e.target.value)}
                    >
                    </TextField>
                    <Button onClick={() => fetchSearch()}>
                        Find Competitions
                    </Button>
          </FormControl>
          {
              result == false ? " " : result.results.length > 0 ?
                    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Competition ID</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Short Name</TableCell>
            <TableCell align="right">Format</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {result.results.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left">{row.id}</TableCell>
              <TableCell component="th" align="left" scope="row">
                  <Link to={`/competition_info/${row.id}`}> {row.name} </Link>
              </TableCell>
              <TableCell align="right">{row.short_name}</TableCell>
              <TableCell align="right">{row.competition_format_display}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              :
              ""
          }

                      <div>
                          <h3>And you can add competition now</h3>
                          <AddCompetitionForm />
                      </div>

      </div>
  );
}