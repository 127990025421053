import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useEffect, useState} from "react";
import {check_token_url} from "../../index";
import TokenChecker from "./TokenChecker";

export default function SearchBarSelector() {
  const [selectedOption, setSelectedOption] = useState(1);

  useEffect(() => {
    const storedOption = localStorage.getItem('base_url');
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);

  const handleOptionChange = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    localStorage.setItem('base_url', option);

    setTimeout(() => {
      window.location.reload()
    }, 1500)

  };


  const linkStyle = {
  margin: "1rem",
  textDecoration: "none",
  color: 'white'
};


  return (
    <Box sx={{ minWidth: 460 }}>
      <FormControl>
        <InputLabel style={linkStyle} id="demo-simple-select-label">Choose type of Platform</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedOption}
          label="Type"
          onChange={handleOptionChange}
          style={linkStyle}
        >
          <MenuItem value={1}>Platform Demo</MenuItem>
          <MenuItem value={3}>Platform Staging</MenuItem>
          <MenuItem value={2}>Platform TEST ENVIRONMENT! DON'T USE IT SERIOUSLY!</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}