import React, {useState, useEffect} from "react";
import {json, Link, useParams} from 'react-router-dom'
import {base_url} from "../../index";
import {
    Avatar,
    Button, Card, CardContent, CardMedia, CircularProgress,
    FormLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    Select, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow,
    TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Alerts from "../Alerts/Alerts";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import {BikeScooter} from "@mui/icons-material";
import HistoryOfChanges from "../History/HistoryOfChanges";
import save_logs from "../../tools/save_logs";



const Team = () => {
    const [commandData, setCommandData] = useState(null);
    const [editedCommandData, setEditedCommandData] = useState(null);
    const [logoFile, setLogoFile] = useState(null)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [teamSquad, setTeamSquad] = useState([])
    const [teamSquadSeason, setTeamSquadSeason] = useState([])
    const [selectedSeasonId, setSelectedSeasonId] = useState(null)
    const [selectedStadium, setSelectedStadium] = useState(null)
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [report, setReport] = useState([])
    const [params, setParams] = useState()
    const [searchPlayerResults, setSearchPlayerResults] = useState([]);
    const [searchResultsTech, setSearchResultsTech] = useState(0);
    const [page, setPage] = useState(1);
    const [add, setAdd] = useState(false)
    const [loading, setLoading] = useState(false);
    const [addedPlayer, setAddedPlayer] = useState(null)

    const base_set_of_season = [
            {
            "id": 134,
            "years": "2024-2025"
        },
        {
            "id": 133,
            "years": "2024"
        },
        {
            "id": 132,
            "years": "2023-2024"
        },
        {
            "id": 131,
            "years": "2023"
        },
        {
            "id": 130,
            "years": "2022-2023"
        },
        {
            "id": 1,
            "years": "2022"
        },
        {
            "id": 129,
            "years": "2021-2022"
        },
        {
            "id": 128,
            "years": "2021"
        },
        {
            "id": 127,
            "years": "2020-2021"
        },
        {
             "id": 126,
            "years": "2020"
        },
        {
            "id": 125,
            "years": "2019-2020"
        },
        {
            "id": 124,
            "years": "2019"
        },
        {
            "id": 123,
            "years": "2018-2019"
        },
        {
            "id": 122,
            "years": "2018"
        },
        {
            "id": 121,
            "years": "2017-2018"
        },
        {
            "id": 120,
            "years": "2017"
        },
        {
            "id": 119,
            "years": "2016-2017"
        },
        {
            "id": 118,
            "years": "2016"
        },
        {
            "id": 117,
            "years": "2015-2016"
        }]

    const { id } = useParams();

    const fetchCommandData = async () => {
        try {
          const response = await fetch(base_url + 'team/' + id);
          const data = await response.json();
          setCommandData(data);
          setEditedCommandData(data);
        } catch (error) {
          console.error('Error fetching command data:', error);
        }
      }

     const fetchTeamSquad = async (seasonId = '') => {
        try {
          const response = await fetch(
            `${base_url}team_players/${id}?year_season_id=${seasonId}`
          );
          const data = await response.json();
          setTeamSquad(data);
          setTeamSquadSeason(data.available_year_seasons);
          // if (teamSquadSeason.length === 0) {
          //     setTeamSquadSeason(base_set_of_season)
          // }
        } catch (error) {
          console.error('Error fetching command squad:', error);
        }
  };

    const fetchTest = async () => {
        const response = await fetch('https://platform.smrtstats.com:8888/api/v1/users/users/?search=gmail.com',{
            headers: {
            'Authorization': 'token 106dc433ce48d1358ad35c01825365f52cc8c753',
          }})
        const data = await response.json()
        console.log(data)
    }

    useEffect(() => {
        fetchCommandData();
        fetchTeamSquad()
        fetchSearch()
        fetchTest()
    }, [page]);


  const handleFormSubmit = async (event) => {
    event.preventDefault();

     const formData = new FormData();
    formData.append('name', editedCommandData.name);
    formData.append('short_name', editedCommandData.short_name);
    formData.append('team_type', editedCommandData.team_type);
    formData.append('team_status', editedCommandData.team_status);

    if (logoFile) {
      formData.append('logo', logoFile);
    }

    if(selectedStadium) {
      await updateStadiumTeams()
      await handleStadiumUpdate()
    }



    try {
      const response = await fetch(base_url + 'team/' + id + '/', {
        method: 'PATCH',
        body: formData,
      });

      if (response.ok) {
          await setAnswer('success')
          await setMessage(response)
          await save_logs('team', id, 'editing', editedCommandData.name)
      } else {
          await setAnswer('error')
          await setMessage(response)
      }
    } catch (error) {
      console.error('Error updating command data:', error);
    }
  };

  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setEditedCommandData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function fetchSearch() {
        if(typeof params !== 'number') {
            try {
                setLoading(true)
                const response = await fetch(`${base_url}player/?search=${params}&page=${page}`)
                const data = await response.json()
                setSearchResultsTech(data.count);
                setSearchResults(data.results);
            }
            catch (e) {
                console.error(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1); // Add 1 to match the current page number for API call
  };

  const updateStadiumTeams = async () => {
      try {
        const response = await fetch(`${base_url}field/${commandData.fields[0].id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch stadium data');
        }
        const stadiumData = await response.json();
        const existingTeamIds = stadiumData.teams.map((team) => team.id);
        const updatedTeamIds = existingTeamIds.filter((teamId) => teamId !== commandData.id);

        const patchResponse = await fetch(`${base_url}field/${commandData.fields[0].id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ teams: updatedTeamIds }),
        });

        if (!patchResponse.ok) {
          throw new Error('Failed to update stadium data');
        }

        console.log('Stadium teams updated successfully');
      } catch (error) {
        console.error('Error updating stadium teams:', error);
      }
};


  const handleStadiumUpdate = async () => {
  try {
    const response = await fetch(`${base_url}field/${selectedStadium.id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch stadium data');
    }

    const stadiumData = await response.json();

    const existingTeamIds = stadiumData.teams.map((team) => team.id);

    existingTeamIds.push(commandData.id);

    const patchResponse = await fetch(`${base_url}field/${selectedStadium.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ teams: existingTeamIds }),
    });

    if (!patchResponse.ok) {
      throw new Error('Failed to update stadium data');
    }

    console.log('Stadium data updated successfully');
  } catch (error) {
    console.error('Error updating stadium data:', error);
  }
};


  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setLogoFile(file);
  };

  const handleSearchTextChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    handleSearch(newSearchText);
  };

  const handleSearch = async (query) => {
    try {
      const response = await fetch(`${base_url}field/?search=${query}`);
      const data = await response.json();
      setSearchResults(data.results);
    } catch (error) {
      console.error('Error searching stadiums:', error);
      setSearchResults([]);
    }
  };

  const handleChange = (event) => {
    const selectedSeasonId = event.target.value;
    setSelectedSeasonId(selectedSeasonId);
    fetchTeamSquad(selectedSeasonId);
  };

  const handleStadiumSelect = (stadium) => {
    setSelectedStadium(stadium);
    fetchTeamSquad()
  };

  const handlePlayerSelect = (playerId) => {
          if (selectedPlayers.includes(playerId)) {
            setSelectedPlayers(selectedPlayers.filter((id) => id !== playerId));
          } else {
            setSelectedPlayers([...selectedPlayers, playerId]);
          }
  };

  const handleSelectAllPlayers = () => {
      if (selectedPlayers.length === teamSquad.players.length) {
        setSelectedPlayers([]);
      } else {
        const allPlayerIds = teamSquad.players.map((player) => player.id);
        setSelectedPlayers(allPlayerIds);
      }
    };

  const handleRemovePlayers = async () => {
      const playersToRemove = selectedPlayers;

      if (playersToRemove.length === 0) {
            alert("No players selected.");
            return;
      }

      const results = await Promise.all(
              selectedPlayers.map(async (player) => {
                console.log(player);
                return await dropPlayerFromTeam(player);
              })
        );

        results.forEach((result) => {
              if (result["details"]) {
                  setReport((prevReport) => [...prevReport, result["details"]]);
              }
              else if (result['result']) {
                  setReport((prevReport) => [...prevReport, result["result"]]);
              }
        });

        await fetchTeamSquad(selectedSeasonId)
        await setAnswer('success')
      await setMessage('ururu')
        };

  const dropPlayerFromTeam = async (player) => {
      try {
        const response = await fetch(`${base_url}drop_player_from_squads/`,
            {
              method: "POST",
              headers: {"content-type": "application/json"},
              body: JSON.stringify({
                "player": player,
                "year_season": selectedSeasonId,
                "team": id
              })
            }
        )
        const data = await response.json();
        return data
      } catch (e) {
        console.error(e)
    }
  }

  const addPlayerToTeam = async (player) => {
      try {

          const confirmed = window.confirm(`Are you sure you want to add a player to the team?`);

          if(confirmed) {
              const response = await fetch(`${base_url}players_in_squad/`,
            {
              method: "POST",
              headers: {"content-type": "application/json"},
              body: JSON.stringify({
                "player": player,
                "year_season": selectedSeasonId,
                "team": id
              })
            }
        )
            const data = await response.json();
            await fetchTeamSquad(selectedSeasonId)
              await setAddedPlayer(data)
            return data
          } else {
              console.log('user clicked cancel')
              return null
          }

      } catch (e) {
        console.error(e)
    }
  }

  return (

    <Box p={2}>
       <Grid container spacing={2}>
         <Grid item xs={6}>


      {commandData && (
        <form onSubmit={handleFormSubmit} style={{marginTop: 20}}>
          <Avatar alt={commandData.name} src={commandData.logo} />

          <TextField
            label="Name"
            name="name"
            fullWidth
            value={editedCommandData.name}
            onChange={handleFieldChange}
            style={{marginTop: 20}}
          />

          <TextField
            label="Short Name"
            name="short_name"
            fullWidth
            value={editedCommandData.short_name}
            onChange={handleFieldChange}
            style={{marginTop: 20}}
          />

          <FormControl fullWidth style={{marginTop: 20}}>
            <InputLabel id="team-type-label">Team Type</InputLabel>
            <Select
              labelId="team-type-label"
              id="team-type"
              name="team_type"
              value={editedCommandData.team_type}
              onChange={handleFieldChange}
            >
              <MenuItem value={1}>Club</MenuItem>
              <MenuItem value={2}>National Team</MenuItem>

            </Select>
          </FormControl>

          <FormControl fullWidth style={{marginTop: 20}}>
            <InputLabel id="team-status-label">Team Status</InputLabel>
            <Select
              labelId="team-status-label"
              id="team-status"
              name="team_status"
              value={editedCommandData.team_status}
              onChange={handleFieldChange}
            >
              <MenuItem value={1}>Professional</MenuItem>
              <MenuItem value={2}>Semi-Professional</MenuItem>
              <MenuItem value={3}>Amateur</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth style={{marginTop: 20}}>
                <TextField
                    label="Choose stadium"
                    type="text"
                     value={searchText}
                    onChange={handleSearchTextChange}
                          />
          </FormControl>

          <p>
            Current stadium: {commandData.fields && commandData.fields.length > 0 ? commandData.fields[0].title : 'Unknown'}
          </p>

          <input type="file" accept="image/*" onChange={handleLogoChange} />

          <Button type="submit" variant="contained" color="primary" style={{marginTop: 20}}>
            Save
          </Button>
                      {
                                answer !== false ?
                                    <Alerts success={answer} type={'Team' } message={message} action={'changed'}/>
                                    :
                                    <div></div>
                            }
        </form>

      )}
         </Grid>

         <Grid item xs={6}>
           <div>

             <List>
                {searchResults.map((stadium) => (
                  <ListItem
                    key={stadium.id}

                    onClick={() => handleStadiumSelect(stadium)}
                  >
                    <ListItemText primary={stadium.title} />
                  </ListItem>
                ))}
            </List>

             {selectedStadium && (
                <div>
                  <h2>Choose Stadium:</h2>
                  <p>Name: {selectedStadium.title}</p>

                </div>


            )}


           </div>
         </Grid>
             <HistoryOfChanges entity_type={"team"} entity_id={id}
              ></HistoryOfChanges>
       </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
           <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="year-season-label">Select Year Season</InputLabel>
        <Select
          labelId="year-season-label"
          id="year-season-select"
          value={selectedSeasonId}
          onChange={handleChange}
          label="Select Year Season"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        {/* {Array.isArray(teamSquadSeason) ? (*/}
        {/*    teamSquadSeason.map((yearSeason) => (*/}
        {/*      <MenuItem key={yearSeason.id} value={yearSeason.id}>*/}
        {/*        {yearSeason.years}*/}
        {/*      </MenuItem>*/}
        {/*  ))*/}
        {/*) : null}*/}

            {Array.isArray(teamSquadSeason) && teamSquadSeason.length > 0 ? (
              teamSquadSeason.map((yearSeason) => (
                <MenuItem key={yearSeason.id} value={yearSeason.id}>
                  {yearSeason.years}
                </MenuItem>
              ))
            ) : Array.isArray(base_set_of_season) ? (
              base_set_of_season.map((yearSeason) => (
                <MenuItem key={yearSeason.id} value={yearSeason.id}>
                  {yearSeason.years}
                </MenuItem>
              ))
            ) : null}


        </Select>
      </FormControl>
    </div>

    <div>



            {
                 teamSquad === undefined ? (
                  <></>
                          ) : teamSquad.players === undefined ? (
                  <></>
          ) : (


    <>
        {selectedSeasonId !== null && selectedSeasonId !== '' ?
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <input
                                type="checkbox"
                                checked={selectedPlayers.length === teamSquad.players.length}
                                onChange={handleSelectAllPlayers}
                            /></TableCell>
                        <TableCell>Player ID</TableCell>
                        <TableCell>Photo</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Birth Date</TableCell>
                        <TableCell>Nation Flag</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teamSquad.players.map((player) => (
                        <TableRow key={player.id}>
                            <TableCell>
                                <input
                                    type="checkbox"
                                    checked={selectedPlayers.includes(player.id)}
                                    onChange={() => handlePlayerSelect(player.id)}
                                />
                            </TableCell>
                            <TableCell>
                                <Link to={`/player/${player.id}`}>{player.id}</Link>
                            </TableCell>
                            <TableCell>
                                <img src={player.photo} alt={player.short_name} height={40}/>
                            </TableCell>
                            <TableCell>{player.display_name}</TableCell>
                            <TableCell>{player.age}</TableCell>
                            <TableCell>{player.birth_date}</TableCell>
                            <TableCell>
                                <img src={player.nation_flag} alt={player.short_name} height={20}/>
                            </TableCell>
                        </TableRow>
                    ))
                    }
                </TableBody>
            </Table>
            :
            <p>
                choose season
            </p>
        }
    </>
  )
}
    <div>
        <Button onClick={() => handleRemovePlayers()}>
            Drop from the Team in this Season
          </Button>
         {
                                answer !== false ?
                                    <Alerts success={answer} type={'Squad' } message={message} action={'updated'}/>
                                    :
                                    <div></div>
                            }
    </div>
        <h1>

        </h1>

    </div>

        </Grid>

        <Grid item xs={6}>
            {
                report == false ? ' ' :
                    <div>
                        <h4>
                        Report:
                        </h4>
                        <div>
                          <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
                                <Typography variant="h6" >Report Messages:</Typography>
                                {report.map((message, index) => (
                                    <Typography style={{margin: '20px'}} key={index}>{message}</Typography>
                                ))}
                          </Paper>
                        </div>

            </div>
            }
            {selectedSeasonId !== null && selectedSeasonId !== '' ?
                <div>

                <div>
                    {
                        addedPlayer === null ? '' : <Typography style={{margin: '20px'}}>{addedPlayer.result} </Typography>
                    }

                    <FormControl>
                        <FormLabel>Enter name of player</FormLabel>
                        <TextField required
                                   onChange={e => setParams(e.target.value)}
                        >
                        </TextField>
                        <Button onClick={() => fetchSearch()}>
                            Find Player
                        </Button>
                    </FormControl>
                    {
                        loading === true ? <CircularProgress/> :
                            searchResults == false ? " "
                                :
                                <div>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>ID</TableCell>
                                                    <TableCell>Full name</TableCell>
                                                    <TableCell>Nickname</TableCell>
                                                    <TableCell>Nationality</TableCell>
                                                    <TableCell>Flag</TableCell>
                                                    <TableCell>Position(s)</TableCell>
                                                    <TableCell>Birthday</TableCell>
                                                    <TableCell>Height (cm)</TableCell>
                                                    <TableCell>Weight (kg)</TableCell>
                                                    <TableCell>Leg</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {searchResults.map((player) => (
                                                    <TableRow key={player.id}>
                                                        <TableCell>
                                                            <Button onClick={() => addPlayerToTeam(player.id)}>➕</Button>
                                                        </TableCell>

                                                        <TableCell>
                                                            <Link to={`/player/${player.id}`}>
                                                                {player.id}
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell>{player.name} {player.middle_name ?? ''} {player.surname ?? ''}</TableCell>
                                                        <TableCell>{player.nickname ?? ''} </TableCell>
                                                        <TableCell>
                                                            {player.nationality.map(nation => nation.name + '  ')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {player.nationality.map(nation => <img src={nation.flag}
                                                                                                   alt={nation.short_name}
                                                                                                   height={20}/>)}
                                                        </TableCell>
                                                        <TableCell>{player.position.map(pos => pos.short_name + '  ')}</TableCell>
                                                        <TableCell>{player.birth_date}</TableCell>
                                                        <TableCell>{player.height}</TableCell>
                                                        <TableCell>{player.weight}</TableCell>
                                                        <TableCell>{player.leg_display}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            count={searchResultsTech}
                                            page={page - 1}
                                            rowsPerPage={10}
                                            onPageChange={handleChangePage}
                                        />
                                    </TableContainer>
                                </div>

                    }
                </div>
                </div>
                :
                <p></p>
            }


        </Grid>
      </Grid>



    </Box>
  );
};

export default Team;
