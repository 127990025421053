import {useState} from "react";
import {TextField, Button, Checkbox, FormControlLabel, Grid, Divider, Chip, Snackbar} from '@mui/material';
import {base_url} from "../../../index";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function CompetitionsConnector() {
    const [id, setId] = useState('');
    const [source, setSource] = useState('TM')
    const [competitions, setCompetitions] = useState([])

    const fakeData = {
        ss_competitions: [
          { id: 1, name: "Premier League", country: "England", year: 2023 },
          { id: 2, name: "La Liga", country: "Spain", year: 2023 },
          { id: 3, name: "Serie A", country: "Italy", year: 2023 },
        ],
        tm_competitions: [
          { id: 4, name: "Bundesliga", country: "Germany", year: 2023 },
          { id: 5, name: "Ligue 1", country: "France", year: 2023 },
          { id: 6, name: "Primeira Liga", country: "Portugal", year: 2023 },
        ]
    };

    const [selectedFirstColumnCompetition, setSelectedFirstColumnCompetition] = useState(null);
    const [selectedSecondColumnCompetition, setSelectedSecondColumnCompetition] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleFirstColumnCompetitionSelect = (competition) => {
        setSelectedFirstColumnCompetition(competition);
        setSelectedSecondColumnCompetition(null);
    };

    const handleSecondColumnCompetitionSelect = (competition) => {
        setSelectedSecondColumnCompetition(competition);
    };

    const handleIdChange = (event) => {
        setId(event.target.value);
    }

    const handleCheckboxChange = (event) => {
        setSource(event.target.checked ? 'TM' : '');
    };

    const handleSubmit = async () => {
        try {
          const response = await fetch(`/competitions_by_nationality/${id}?source=${source}`);
          const data = await response.json();
          console.log(data);
        } catch (error) {
          console.error('Ошибка при выполнении запроса:', error);
          setCompetitions(fakeData)
        }
    };

    const handleConnectCompetitions = async () => {
        try {
          const response = await fetch('/competition_connect/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              firstCompetitionId: selectedFirstColumnCompetition.id,
              secondCompetitionId: selectedSecondColumnCompetition.id
            })
          });
          const data = await response.json();
          setSnackbarMessage(data.message);
          setSnackbarOpen(true);
        } catch (error) {
            setSnackbarMessage('aaa');
          setSnackbarOpen(true);
          console.error('Ошибка при выполнении запроса:', error);
        }
  };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false); // Закрытие Snackbar
  };



    return(
        <>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              label="ID"
              variant="outlined"
              value={id}
              onChange={handleIdChange}
              fullWidth
            />
               <FormControlLabel
                  control={
                    <Checkbox
                      checked={source === 'TM'}
                      onChange={handleCheckboxChange}
                      name="source"
                    />
              }
              label="TM"
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
    </Grid>

           <Grid container spacing={2}>
               <Grid item xs={12}>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h6" align="center">Выбранные турниры</Typography>
                <Grid container justifyContent="center" spacing={1}>
                  {selectedFirstColumnCompetition && (
                    <Grid item>
                      <Chip
                        label={selectedFirstColumnCompetition.name}
                        onDelete={() => setSelectedFirstColumnCompetition(null)}
                        color="primary"
                      />
                    </Grid>
                  )}
                  {selectedSecondColumnCompetition && (
                    <Grid item>
                      <Chip
                        label={selectedSecondColumnCompetition.name}
                        onDelete={() => setSelectedSecondColumnCompetition(null)}
                        color="secondary"
                      />
                    </Grid>
                  )}
                </Grid>
                   <p>

                   </p>
                   {selectedFirstColumnCompetition && selectedSecondColumnCompetition && (
                         <Grid item xs={12} container justifyContent="center">
                          <Button variant="contained" color="primary" onClick={handleConnectCompetitions}>
                            Связать турниры с внешним источником
                          </Button>
                     </Grid>

                      )}
                      <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        message={snackbarMessage}
                        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                      />
                   <p>

                   </p>
               </Grid>
      <Grid item xs={6}>
        <Paper>
          <Typography variant="h6" align="center">SS Competitions</Typography>
          {competitions.ss_competitions && competitions.ss_competitions.map((competition, index) => (
            <Button
              key={index}
              onClick={() => handleFirstColumnCompetitionSelect(competition)}
              fullWidth
              disabled={selectedFirstColumnCompetition && selectedFirstColumnCompetition.id === competition.id}
            >
              {competition.name}, {competition.country}, {competition.year}
            </Button>
          ))}
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <Typography variant="h6" align="center">TM Competitions</Typography>
          {competitions.tm_competitions && competitions.tm_competitions.map((competition, index) => (
            <Button
              key={index}
              onClick={() => handleSecondColumnCompetitionSelect(competition)}
              fullWidth
              disabled={!selectedFirstColumnCompetition || (selectedSecondColumnCompetition && selectedSecondColumnCompetition.id === competition.id)}
            >
              {competition.name}, {competition.country}, {competition.year}
            </Button>
          ))}
        </Paper>
      </Grid>

    </Grid>
            </>
    )
}