import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import {base_url} from "../../index";
import Grid from "@mui/material/Grid";
import {Button, Checkbox, CircularProgress, FormLabel, List, ListItem, ListItemText, TextField} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Alerts from "../Alerts/Alerts";
import Typography from "@mui/material/Typography";
import save_logs from "../../tools/save_logs";
import HistoryOfChanges from "../History/HistoryOfChanges";


export default function Match() {
    const [param, setParam] = useState()
    const [match, setMatch] = useState()
    const [home_team, setHomeTeam] = useState()
    const [away_team, setAwayTeam] = useState()
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [editedMatchData, setEditedMatchData] = useState(null)
    const [isEditing, setIsEditing] = useState(false);
  const [newLink, setNewLink] = useState('');
    const [searchTextStad, setSearchTextStad] = useState('');
  const [searchResultsStad, setSearchResultsStad] = useState([]);
  const [selectedStadium, setSelectedStadium] = useState(null);

    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()

    const [search_result, setSearchResult] = useState()
    const [search_await_result, setSearchAwaitResult] = useState()
    const [result, setResult] = useState()

    const [loading, setLoading] = useState(false);
    const [in_start, setInStart] = useState(false)

    const [home_team_formation, setHomeTeamForm] = useState([])
    const [away_team_formation, setAwayTeamForm] = useState([])

    const [video, setVideo] = useState(null)

    const addPlayerToHomeSquad = (player_id, player_name) => {
        let player = {
            "player_id": player_id,
            "player_name": player_name
        }

        setHomeTeamForm(old_array => [...old_array, player])
    }
    const handleSaveClick = async () => {
        const isConfirmed = window.confirm('Are you sure you want to change this video?');

      if (isConfirmed) {
        try {
          const response = await fetch(`${base_url}video/${video}/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              link: newLink,
            }),
          });

          setIsEditing(false)
          if (response.ok) {
            console.log('Video updated successfully!');
            await save_logs('match', id, 'changed video', 'a!')
          } else {
            console.error('Failed to update video');
          }
        } catch (error) {
          console.error('An error occurred during the update:', error);
        }

        setVideo(null)
      }
    };


    const handleDateChange = (date) => {
    setSelectedDate(date);
  };

    const handleTimeChange = (date) => {
    setSelectedTime(date);
  };

    const handleSearch = async (query) => {
    try {
      const response = await fetch(`${base_url}field/?search=${query}`);
      const data = await response.json();
      setSearchResultsStad(data.results);
    } catch (error) {
      console.error('Error searching stadiums:', error);
      setSearchResultsStad([]);
    }
  };


    const handleSearchTextChange = (event) => {
    const newSearchText = event.target.value;
        setSearchTextStad(newSearchText);
        handleSearch(newSearchText);
  };

    let {id} = useParams()

    useEffect(() => {
        fetchMatch()
    }, [])

    const fetchMatch = async () => {
        try {
            setLoading(true)
             const response = await fetch(base_url + 'match/' + id + '/')
            const data = await response.json()
            await setMatch(data)
            await setHomeTeam(data.home_squad)
            await setAwayTeam(data.away_squad)
        }
        catch (e) {
            console.error(e)
        }
       finally {
            setLoading(false)
        }
    }

    const updateMatch = async () => {
          console.log(selectedDate);
          console.log(selectedStadium);

          let body = {};

          if (selectedStadium) {
            body["field"] = selectedStadium.id;
          }

          if (selectedDate) {
            body["date"] = selectedDate;
          }

          const response = await fetch(`${base_url}match/${id}/`, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          });

          const data = await response.json();
          if(data.id) {
              await selectedDate(null)
              await searchResultsStad([])
              await selectedStadium(null)
              await save_logs('match', id, 'editing', 'match')
              await fetchMatch()
          }
        };

    const handleEditClick = (index, link, video_id) => {
    setVideo(video_id)
    setNewLink(link);
    setIsEditing(true);
  };

  // const handleSaveClick = () => {
  //
  //
  // };


    const addVideoToMatch = async () => {
        let formData = new FormData()
        formData.append('match', id)
        formData.append('link', param)

        try {
            const fetchResponse = await fetch(base_url + 'video/', {
                    method: "POST",
                    body: formData,
            });
            const data = await fetchResponse.json();
            if (data.id) {
                await setAnswer('success')
                await setMessage(data)
                await setParam(undefined)
                await save_logs('match', id, 'added video', param)
            } else {
                await setAnswer('error')
                await setMessage(data)
            }

        }
        catch (e) {
            console.log(e)
        }

    }

    const handleFieldChange = (event) => {
    const { data, value } = event.target;
    setEditedMatchData((prevState) => ({
      ...prevState,
      [data]: value,
    }));
  };

    return (
        <div>
            {
                loading === true ? <CircularProgress/>
                    :

                    <div>

                        <div>
                            {
                                home_team === undefined ? "" :

                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px' }}>
                                  <img
                                    src={home_team?.team?.logo || 'https://example.com/default-logo.png'}
                                    alt={home_team?.team?.name || 'Home Team Logo'}
                                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                  />
                                  <Typography variant="body1">{home_team?.team?.name || 'Home Team'} <Button>
                                                        <Link to={`/squad/${id}/${home_team.id}`}>
                                                            go to Squad
                                                        </Link>
                                                    </Button></Typography>


                                  <img
                                    src={away_team?.team?.logo || 'https://example.com/default-logo.png'}
                                    alt={away_team?.team?.name || 'Away Team Logo'}
                                    style={{ width: '30px', height: '30px', marginLeft: '20px' }}
                                  />
                                  <Typography variant="body1">{away_team?.team?.name || 'Away Team'}

                                     <Button>
                                                <Link to={`/squad/${id}/${away_team.id}`}>
                                                    Go to Squad
                                                </Link>
                                            </Button>
                                  </Typography>

                              </div>
                            }
                            {
                                match === undefined ? " " :<div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                                      <img
                                        src={match.season.country_flag || 'https://example.com/default-flag.png'}
                                        alt={match.season.title || 'Country Flag'}
                                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                      />
                                      <img
                                        src={match.season.competition_logo || 'https://example.com/default-logo.png'}
                                        alt={match.season.title || 'Competition Logo'}
                                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                      />
                                      <Typography variant="body1">{match.season.title}</Typography>
                                    </div>
                            }
                             {
                                match === undefined ? " " :<div style={{alignItems: 'center', marginBottom: '20px' }}>
                                      <Typography variant="body1">Date - {match.date} </Typography>
                                      <Typography variant="body1">Place - {match.field.title}</Typography>
                                    </div>
                            }



                        </div>

                        <Grid container spacing={3} style={{marginTop: 25}}>
                          <Grid item xs={12} sm={6}>
                              <FormControl>
                                  <FormLabel>
                                      Change date of the match
                                  </FormLabel>
                                 {/*<LocalizationProvider dateAdapter={AdapterDayjs}>*/}
                                 {/* <DemoContainer components={['TimePicker']}>*/}
                                 {/*   <TimePicker label="Basic time picker" onChange={handleTimeChange}/>*/}
                                 {/* </DemoContainer>*/}
                                 {/*</LocalizationProvider>*/}
                                  <TextField
                                    type={"date"}
                                    name="date"
                                    defaultValue={selectedDate}
                                    onChange={event => setSelectedDate(event.target.value)}
                                  />


                                  <TextField
                                          label="Choose stad"
                                          type="text"
                                           value={searchTextStad}
                                          onChange={handleSearchTextChange}
                                          />

                                          <List>
                                            {searchResultsStad.map((stadium) => (
                                              <ListItem
                                                key={stadium.id}

                                                onClick={() => setSelectedStadium(stadium)}
                                              >
                                                <ListItemText primary={stadium.title} />
                                              </ListItem>
                                            ))}
                                          </List>

                          {selectedStadium && (
                            <div>
                              <h2>Choose Stadium:</h2>
                              <p>Name: {selectedStadium.title}</p>

                            </div>
                          )}
                                                      <Button onClick={() => updateMatch()}>
                                      Saaaaave
                                  </Button>


                              </FormControl>
                            <FormControl fullWidth>
                              <FormLabel>
                                Please, insert a link to the video
                              </FormLabel>
                              <TextField
                                fullWidth
                                variant="outlined"
                                name="file"
                                onChange={e => setParam(e.target.value)}
                              />

                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => addVideoToMatch()}
                                style={{ marginTop: '10px' }}
                              >
                                Add Video Link to Match
                              </Button>

                              {answer !== false && (
                                <Alerts success={answer} type="video to match" message={message} action="added" />
                              )}
                            </FormControl>

                            <div style={{ marginTop: '20px' }}>
                              <FormControl>
                                <FormLabel>
                                  Or upload the video directly
                                </FormLabel>
                                <a href={`https://video3.smrtstats.com:85/${id}`} style={{ textDecoration: 'none', color: '#007bff' }}>
                                  Add video
                                </a>
                              </FormControl>
                            </div>
                               {match === undefined ? '' : match.videos === undefined ? ' ' : match.videos.map((video, index) => (
                                    <div key={index} style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      {isEditing ? (
                                        <div>
                                          <TextField
                                            label={`Edit Video ${index + 1}`}
                                            value={newLink}
                                            onChange={(e) => setNewLink(e.target.value)}
                                          />
                                          <Button onClick={handleSaveClick}>Save</Button>
                                        </div>
                                      ) : (
                                        <div>
                                          <a href={video.link} target="_blank" rel="noopener noreferrer">
                                            Video {index + 1}
                                          </a>
                                          {/*<div onClick={() => handleDeleteVideo(video.id)}>*/}
                                          {/*  ❌*/}
                                          {/*</div>*/}
                                          <div onClick={() => handleEditClick(index, video.link, video.id)}>
                                            ✏️
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                           <h1>

                           </h1>
                          </Grid>
                    </Grid>
                    <HistoryOfChanges entity_id={id} entity_type={'match'}></HistoryOfChanges>
                    </div>
            }
        </div>
    )

}