import React, {useEffect, useState} from "react";
import {base_url, base_url_long} from "../../../index";
import Grid from "@mui/material/Grid";
import {
    Button, Checkbox,
    CircularProgress, FormControlLabel, List, ListItem, ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TablePagination,
    TableRow, TextField
} from "@mui/material";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Alerts from "../../Alerts/Alerts";

export default function PlayersMerging() {
    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsTech, setSearchResultsTech] = useState(0);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [isInitialSearch, setIsInitialSearch] = useState(true);
    const [playerId, setPlayerId] = useState('');
    const [playersData, setPlayersData] = useState([]);
    const [basedPlayer, setBasedPlayer] = useState(null);
  const [otherPlayers, setOtherPlayers] = useState([]);
  const [afterMerge, setAfterMerge] = useState(null)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()


    const handlePotentialDoubles = async () => {
        try {
         setLoading(true)
        const response = await fetch(`${base_url_long}merge/players/find_clones/?page=${page}`);
        const data = await response.json();
        setSearchResultsTech(data.count);
        setSearchResults(data.result);

        if (isInitialSearch) {
          setIsInitialSearch(false); // Set isInitialSearch to false after first successful search
        }
      }
     catch (e) {
       console.error(e)
     }
     finally {
        setLoading(false)
      }
    }

      const handleCheckboxChange = (player) => {
    const updatedSelectedPlayers = [...selectedPlayers];
    if (updatedSelectedPlayers.includes(player)) {
      updatedSelectedPlayers.splice(updatedSelectedPlayers.indexOf(player), 1);
    } else {
      updatedSelectedPlayers.push(player);
    }
    setSelectedPlayers(updatedSelectedPlayers);
  };

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
     };

    const handlePlayerIdChange = (e) => {
        setPlayerId(e.target.value);
    };

    const handleUploadMergePlayers = async () => {
        try {
            setLoading(true)
          const response = await fetch(`${base_url_long}merge/players/${playerId}/`);
          const data = await response.json();
          if(data.result) {
               await setPlayersData(data.result);
          }else {
              alert(data.detail)
          }

        } catch (error) {
          console.error('Error merging players:', error);
        }
        finally {
            setLoading(false)
        }
  };

    const mergePlayers = async () => {
              try {
                  setLoading(true)
                const response = await fetch(`${base_url_long}merge/players/draft/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    main: selectedPlayers[0].id,
                    to_merge: selectedPlayers.slice(1).map((player) => parseInt(player.id)),
                  }),
                });

                if (!response.ok) {
                  // Handle the error here, e.g., throw an error or show a message
                  throw new Error('Error merging players');
                }

                // Handle the response here, e.g., display a success message or update the UI
                const data = await response.json();
                await setAfterMerge(data)
                await setAnswer(false)
                await setMessage(undefined)

                console.log('Merge response:', data);
              } catch (error) {
                console.error('Merge players error:', error);
              }
              finally {
                  setLoading(false)
              }
};

    const confirmMergePlayers = async () => {
              try {
                const response = await fetch(`${base_url_long}merge/players/process/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    main: selectedPlayers[0].id,
                    to_merge: selectedPlayers.slice(1).map((player) => parseInt(player.id)),
                  }),
                });

                if (!response.ok) {
                  // Handle the error here, e.g., throw an error or show a message
                  throw new Error('Error merging players');
                }

                // Handle the response here, e.g., display a success message or update the UI
                const data = await response.json();
                 if (data.result_merge.id) {
                    await setAnswer('success')
                    await setMessage(data)
                     await setAfterMerge(null)
                  await setSelectedPlayers([])
                     await setPlayersData([])
                 }

                console.log('Merge response:', data);
              } catch (error) {
                console.error('Merge players error:', error);
                await setAnswer('success')
                await setMessage('')
              } finally {
              }
};


    useEffect(() => {

            handlePotentialDoubles(page);

    }, [page, isInitialSearch])

    return (
        <div style={{marginTop: 100}}>
      <Grid container spacing={2}>

        <Grid item xs={6}>

             <div>
                  <TextField
                    label="Enter Player ID"
                    value={playerId}
                    onChange={handlePlayerIdChange}
                  />
                  <Button variant="contained" color="primary" onClick={handleUploadMergePlayers}>
                    Find Player
                  </Button>
                 { playersData ? (
                     <>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Checkbox</TableCell>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Surname</TableCell>
                            <TableCell>Birth Date</TableCell>
                            <TableCell>Count of matches</TableCell>
                            <TableCell>Last Match</TableCell>
                            {/* Add more columns as needed */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {loading === true ? <CircularProgress /> : playersData === undefined ? ' ' : playersData.map((player) => (
                            <TableRow key={player.id}>
                              <TableCell>
                                 <Checkbox
                        checked={selectedPlayers.includes(player)}
                        onChange={() => handleCheckboxChange(player)}
                      />
                              </TableCell>
                              <TableCell>{player.id}</TableCell>
                              <TableCell>{player.name}</TableCell>
                              <TableCell>{player.surname}</TableCell>
                              <TableCell>{player.birth_date}</TableCell>
                              <TableCell>{player.matches_count}</TableCell>
                              <TableCell>  {player.matches.length > 0 ? player.matches[0].date : 'No matches'}</TableCell>
                              {/* Add more cells for additional columns */}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    </>
                  ) : null}
                </div>


        </Grid>


        <Grid item xs={6}>
            <div>
                Based player:
                {selectedPlayers.length === 0 ? 'No selected players' : selectedPlayers[0].id}
            </div>
             <div>
            Selected Players: {selectedPlayers === undefined ? ' ' : selectedPlayers.slice(1).map((player) => player.id).join(', ')}
          </div>

            <Button onClick={() => mergePlayers()}>
                To Merge Players
            </Button>

            <p>

            </p>

            {
                 loading === true ? <CircularProgress /> : afterMerge === null ? ' ' :
                    <div>


                    After merging several players, we got the following result:
                        {
                            <div>
                                { afterMerge.after_merge === undefined ? ' ' :

                                    <Paper elevation={3} style={{padding: '20px', margin: '20px'}}>
                                        <Typography variant="h4" gutterBottom>
                                            Player Details
                                        </Typography>
                                        <List>
                                            <ListItem>
                                                <ListItemText primary={`ID: ${afterMerge.after_merge.id}`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={`Name: ${afterMerge.after_merge.name} ${afterMerge.after_merge.surname}`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={`Date of Birth: ${afterMerge.after_merge.birth_date}`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`Height: ${afterMerge.after_merge.height} cm`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`Weight: ${afterMerge.after_merge.weight} kg`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`Leg: ${afterMerge.after_merge.leg}`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`Gender: ${afterMerge.after_merge.gender}`}/>
                                            </ListItem>
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={`Nationality: ${afterMerge.after_merge.nationality.join(', ')}`}/>*/}
                                            {/*</ListItem>*/}
                                            {/*<ListItem>*/}
                                            {/*    <ListItemText primary={`Position: ${afterMerge.after_merge.position.join(', ')}`}/>*/}
                                            {/*</ListItem>*/}
                                            <ListItem>
                                                <ListItemText
                                                    primary={`Merged: ${afterMerge.after_merge.merged ? 'Yes' : 'No'}`}/>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText primary={`New count of matches: ${afterMerge.main_matches_count + afterMerge.merge_matches_count}`}/>
                                            </ListItem>


                                        </List>


                                    </Paper>
                                }
                            if you are sure, you can confirm the data merge

                                        <Button onClick={() => confirmMergePlayers()}>
                                            Confirm the data merge
                                        </Button>

                            </div>
                        }
                    </div>
            }
              {
                                      answer !== false ?
                                        <Alerts success={answer} type={'player'} message={message} action={'merged'}/>
                                        :
                                        <div></div>
                                }

        </Grid>
      </Grid>
    </div>
    )
}