import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Competition from "./components/Competitions/Competition";
import Team from "./components/Teams/Team";
import Season from "./components/Competitions/Season";
import Match from "./components/Matches/Match";
import Player from "./components/Players/Player";
import SquadFormation from "./components/Matches/SquadFormation";
import Login from "./components/Login/Login";
import Squad from "./components/Matches/Squad";
import Stadiums from "./components/OtherData/Stadiums";
import Stadium from "./components/OtherData/Stadium";
import Merging from "./components/Tools/Merging";
import PlayersMerging from "./components/Tools/Merging/PlayersMerging";
import TeamsMerging from "./components/Tools/Merging/TeamsMerging";
import Representation from "./components/Players/Representation";
import DataConnector from "./components/Tools/DataConnector";
import CompetitionsConnector from "./components/Tools/DataConnector/CompetitionsConnector";
import TeamsConnector from "./components/Tools/DataConnector/TeamsConnector";
import PlayersConnector from "./components/Tools/DataConnector/PlayersConnector";
import OptaVideoUploader from "./components/Tools/OptaVideoUploader";
import VideoMarking from "./components/Competitions/VideoMarking";

function App() {
  return (
          <Router>
              {
                  localStorage.getItem("token") ?
                      <div>
                          <Header />
                                <Container maxWidth="lg">

                                    <Routes>
                                      <Route exact path="/" element={<Menu />}></Route>
                                      <Route path="/competition_info/:id" element={<Competition />}></Route>
                                      <Route path="/season/:id" element={<Season />}></Route>
                                      <Route path="/video/:id" element={<VideoMarking />}></Route>
                                      <Route path="/team/:id" element={<Team />}></Route>
                                      <Route path="/match/:id" element={<Match />}></Route>
                                      <Route path="/squads/:id" element={<SquadFormation />}></Route>
                                      <Route path="/squad/:match_id/:squad_id" element={<Squad />}></Route>
                                      <Route path="/player/:id" element={<Player />}></Route>
                                      <Route path="/reprs/:id" element={<Representation />}></Route>
                                      <Route path="/stadium/" element={<Stadiums />}></Route>
                                      <Route path="/stadium/:id" element={<Stadium />}></Route>
                                      <Route path="/merging/" element={<Merging />}></Route>
                                      <Route path="/merge_players/" element={<PlayersMerging />} ></Route>
                                      <Route path="/merge_teams/" element={<TeamsMerging />} ></Route>
                                      <Route path="/connector/" element={<DataConnector />}></Route>
                                      <Route path="/connector/comp_connect/" element={<CompetitionsConnector />}></Route>
                                      <Route path="/connector/team_connect/" element={<TeamsConnector />}></Route>
                                      <Route path="/connector/player_connect/" element={<PlayersConnector />}></Route>
                                      <Route path="/opta_video_uploader/" element={<OptaVideoUploader />}></Route>
                                    </Routes>

                                </Container>
                      </div>:
                      <div>
                          <Login />
                      </div>
              }


          </Router>
  );
}

export default App;
