import {check_token_url} from "../../index";
import {useEffect} from "react";

export default function TokenChecker() {
    const url = check_token_url
    const token = localStorage.getItem("token")

     const check_token = async () => {
         try {
             const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `token ${token}`,
              },
            })
             const data = await response

             if (data.status !== 401) {
                 console.log(true)
             } else
             {
                  localStorage.removeItem('token')
                  setTimeout(() => {
                    window.location.reload()
                  }, 1500)
             }
            console.log(data.status)
         }

         catch (e) {
             console.log(e)
         }

    }

     useEffect(() => {
            check_token()
        }, [])

    return null
}