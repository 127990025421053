import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Matches from "../Matches/Matches";
import AddCompetitionForm from "../Competitions/AddCompetitionForm";
import SearchCompetitions from "../Competitions/SearchCompetitions";
import AddMatches from "../Matches/Matches";
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Teams from "../Teams/Teams";
import Players from "../Players/Players";
import SearchBarSelector from "../Header/SearchBarSelector";
import OtherData from "../OtherData/OtherData";
import Tools from "../Tools/Tools";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Menu() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Seasons" {...a11yProps(0)} />
          <Tab label="Competitions" {...a11yProps(1)} />
          <Tab label="Teams" {...a11yProps(2)} />
          <Tab label="Players" {...a11yProps(3)} />
          <Tab label="Other Data" {...a11yProps(4)} />
          <Tab label="Tools" {...a11yProps(5)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
          <AddMatches />
      </TabPanel>
      <TabPanel value={value} index={1}>
          <h3>This page is designed to search, change, create and delete competitions in our database. First, you need
              to try to find the competition of interest. If the search is unsuccessful, then you can create.</h3>
          <SearchCompetitions />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Teams />
      </TabPanel>
        <TabPanel value={value} index={3}>
        <Players />
      </TabPanel>
        <TabPanel value={value} index={4}>
        <OtherData />
      </TabPanel>

        <TabPanel index={value} value={5}>
            <Tools />
        </TabPanel>
    </Box>
  );
}