import * as React from "react";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import {
    Button,
    CircularProgress,
    FormLabel,
    Paper,
    Select,
    TableContainer,
    TablePagination,
    TextField
} from "@mui/material";
import {base_url} from "../../index";
import MenuItem from "@mui/material/MenuItem";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Link} from "react-router-dom";
import Table from "@mui/material/Table";
import Alerts from "../Alerts/Alerts";
import Grid from "@mui/material/Grid";
import save_logs from "../../tools/save_logs";


export default function Players() {
    const [params, setParams] = useState()
    const [searchResults, setSearchResults] = useState([]);
  const [searchResultsTech, setSearchResultsTech] = useState(0);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [page, setPage] = useState(1);
    const [add, setAdd] = useState(false)

    const [nations, setNations] = useState([])
    const [positions, setPositions] = useState([])

    const [image, setImage] = useState()

    const [name, setName] = useState()
    const [surname, setSurname] = useState()
    const [middle_name, setMiddleName] = useState()
    const [nickname, setNickname] = useState()
    const [birth_date, setBirthDate] = useState()
    const [height, setHeight] = useState()
    const [weight, setWeight] = useState()
    const [leg, setLeg] = useState()
    const [nation, setNation] = useState()
    const [position, setPosition] = useState()
    const [gender, setGender] = useState()

    const [nationalityFound, setNationalityFound] = useState([])
  const [natQuerySearch, setNatQuerySearch] = useState('')
    const [selectedNationality, setSelectedNationality] = useState([])
    const [positionFound, setPositionFound] = useState([])
  const [posQS, setPosQS] = useState('')
    const [selectedPosition, setSelectedPosition] = useState([])

    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);

    console.log(
        birth_date
    )

    useEffect(() => {
        fetchSearch(page)
    }, [page])

    function onImageChange(e) {
        setImage([...e.target.files])
    }

    async function fetchSearch() {
        if(typeof params !== 'number') {
            try {
                setLoading(true)
                const response = await fetch(`${base_url}player/?search=${params}&page=${page}`)
                const data = await response.json()
                setSearchResultsTech(data.count);
                setSearchResults(data.results);
            }
            catch (e) {
                console.error(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

    const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Add 1 to match the current page number for API call
  };

    const addPlayer = async () => {
      try {
        setLoading(true);

        const requestBody = {
          nationality: selectedNationality.map(nationality => nationality.id),
          position: selectedPosition.map(pos => pos.id),
          name,
          surname,
          middle_name: middle_name,
          nickname,
          birth_date,
          height,
          weight,
          leg,
          gender,
        };

        const fetchResponse = await fetch(`${base_url}player_create/`, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-type': 'application/json',
          },
        });

        const data = await fetchResponse.json();

        if (data.id) {
              setAnswer('success');
              setMessage(data);

              console.log('Adding player:', data);
              await save_logs('player', data.id, 'add', name + ' ' + surname)

              setName(undefined);
              setSurname(undefined);
              setNation(undefined);
              setPosition(undefined);
              setSelectedPosition([]);
              setSelectedNationality([]);
              setGender(undefined);


              await addPlayerPhoto(data.id);
            } else {
              setAnswer('error');
              setMessage(data);
            }
          } catch (error) {
            console.error('Error in addPlayer:', error);
          } finally {
            setLoading(false);
          }

    };



        const handleNationalitySearchChange = async (event) => {
          const { value } = event.target;
          setNatQuerySearch(value);

          try {
            const response = await fetch(base_url + 'nationality/?search=' + value);
            const data = await response.json();
            setNationalityFound(data.results);
          } catch (error) {
            console.error('Error searching nationalities:', error);
          }
        };

  const handlePositionSearchChange = async (event) => {
      const { value } = event.target;
      setPosQS(value);

      try {
        const response = await fetch(base_url + 'position/?search=' + value);
        const data = await response.json();
        setPositionFound(data.results);
      } catch (error) {
        console.error('Error searching nationalities:', error);
      }
    };

    const addItemToNationality = (id, name, flag) => {
  setSelectedNationality((prevArray) => [...prevArray, {
      "id": id,
      "name": name,
      "flag": flag
  }]);
};

  const addItemToPosition = (id, name) => {
  setSelectedPosition((prevArray) => [...prevArray, {
      "id": id,
      "name": name
  }]);
    };


    const addPlayerPhoto = async (id) => {
        let formData = new FormData()
        formData.append('player', id)
        formData.append('image', image[0])

        try {
            const response = await fetch(base_url + "player_photo/", {
                method: "POST",
                body: formData
            })
            const data = await response.json()
            console.log(data)
        }
        catch (e) {
            console.log(e)
        }
    }


    return (
        <div>
             <FormControl>
                <FormLabel>Enter name or id of player</FormLabel>
                    <TextField required
                    onChange={e => setParams(e.target.value)}
                    >
                    </TextField>
                    <Button onClick={() => fetchSearch()}>
                        Find Player
                    </Button>
          </FormControl>
            {
                loading === true ? <CircularProgress /> :
                    searchResults == false ? " "
                    :
                    <div>
                        <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Full name</TableCell>
                        <TableCell>Nickname</TableCell>
                        <TableCell>Nationality</TableCell>
                        <TableCell>Flag</TableCell>
                        <TableCell>Position(s)</TableCell>
                        <TableCell>Birthday</TableCell>
                        <TableCell>Height (cm)</TableCell>
                        <TableCell>Weight (kg)</TableCell>
                        <TableCell>Leg</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchResults.map((player) => (
                        <TableRow key={player.id}>

                          <TableCell>
                            <Link to={`/player/${player.id}`}>
                              {player.id}
                            </Link>
                          </TableCell>
                          <TableCell>{player.name} {player.middle_name ?? ''} {player.surname ?? ''}</TableCell>
                          <TableCell>{player.nickname ?? ''} </TableCell>
                          <TableCell>
                            {player.nationality.map(nation => nation.name + '  ')}
                          </TableCell>
                          <TableCell>
                             {player.nationality.map(nation =>  <img src={nation.flag} alt={nation.short_name} height={20} />)}
                          </TableCell>
                          <TableCell>{player.position.map(pos => pos.short_name + '  ')}</TableCell>
                          <TableCell>{player.birth_date}</TableCell>
                          <TableCell>{player.height}</TableCell>
                          <TableCell>{player.weight}</TableCell>
                          <TableCell>{player.leg_display}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
          <TablePagination
            component="div"
            count={searchResultsTech}
            page={page - 1}
            rowsPerPage={10}
            onPageChange={handleChangePage}
          />
        </TableContainer>
                    </div>
            }
            <Grid container spacing={2}>
                <Grid item xs={6}>



                    <div>
                          <h4>The player was not found? Want to create? </h4>
                        <Button onClick={() => setAdd(true)}>Create Player</Button>
                        <p>

                        </p>
                        {add === false ? " "
                        :
                            <FormControl>
                            <FormLabel>Type Player Name (example: Frank)</FormLabel>
                            <TextField onChange={e => setName(e.target.value)}></TextField>

                            <FormLabel>Type Player Surname (example: Lampard)</FormLabel>
                            <TextField onChange={e => setSurname(e.target.value)}></TextField>

                            <FormLabel>Type Player Middle Name (example: James) </FormLabel>
                            <TextField onChange={e => setMiddleName(e.target.value)}></TextField>

                            <FormLabel>Type Player Nickname (Only if there is a clear nickname (Ronaldinho or Kaka))</FormLabel>
                            <TextField onChange={e => setNickname(e.target.value)}></TextField>

                            <FormLabel>Type Height of Player (Cm)</FormLabel>
                            <TextField onChange={e => setHeight(e.target.value)}></TextField>

                            <FormLabel>Type Weight of Player (Kg)</FormLabel>
                            <TextField onChange={e => setWeight(e.target.value)}></TextField>

                                            <TextField
                                label="Search Nationality"
                                name="nationalitySearch"
                                fullWidth
                                value={natQuerySearch}
                                onChange={handleNationalitySearchChange}
                                style={{ marginTop: 5}}
                            />

                            <TextField
                                label="Search Position"
                                name="positionSearch"
                                fullWidth
                                value={posQS}
                                onChange={handlePositionSearchChange}
                                style={{ marginTop: 5}}
                                />

                            <FormLabel>Choose Birthday of Player</FormLabel>
                            <TextField type="date" onChange={e => setBirthDate(e.target.value)}>

                            </TextField>

                            <FormLabel>Choose gender of Player</FormLabel>
                            <Select value={gender} onChange={e => setGender(e.target.value)}>
                                 <MenuItem value={'M'}>Male</MenuItem>
                                 <MenuItem value={'F'}>Female</MenuItem>
                            </Select>

                            <FormLabel>Choose Player leg</FormLabel>
                            <Select value={leg} onChange={e => setLeg(e.target.value)}>
                                <MenuItem value={'R'}>Right</MenuItem>
                                <MenuItem value={'L'}>Left</MenuItem>
                                <MenuItem value={'R'}>Right + Left</MenuItem>
                            </Select>

                             <Button variant="contained" component="label">
                                            Upload Photo of Player <input accept="image/*" type="file" onChange={onImageChange}/>
                             </Button>

                                {
                                    loading ?

                                        <CircularProgress />
                                        :
                                        answer !== false ?
                                        <Alerts success={answer} type={'player'} message={message} action={'added'}/>
                                        :
                                        <div></div>
                                }


                                 <div>
                                {image === undefined ? " "
                                    :
                                    <p> The Player photo is added </p>
                                }
                            </div>



                            <Button onClick={() => addPlayer()}>
                                Add player
                            </Button>

                        </FormControl>
                        }

                    </div>
                </Grid>
                <Grid item xs={6}>
                    {/* Right side */}
  {nationalityFound.length > 0 && (
    <div>
      <h4>Nationalities:</h4>
      {nationalityFound.map((nationality) => (
        <div style={{ marginTop: 20}} onClick={() => addItemToNationality(nationality.id, nationality.name, nationality.flag)} key={nationality.id}>
         <img style={{width: 15, height: 10}}  src={nationality.flag} alt={' '} /> {nationality.name}
        </div>
      ))}
    </div>
  )}

  {selectedNationality.length > 0 && (
    <div>
      <h4>New nationalities for the player:</h4>
      <ul>
        {selectedNationality.map((nation) => (
          <li key={nation.id} style={{listStyleType: 'none'}}>
              <img style={{width: 15, height: 10}} src={nation.flag} alt={' '} />
              {" "}{nation.name}</li>
        ))}
      </ul>
    </div>
  )}

  {positionFound.length > 0 && (
    <div style={{ marginTop: 35 }}>
      <h4>Positions:</h4>
      {positionFound.map((position) => (
        <div style={{ marginTop: 20}} onClick={() => addItemToPosition(position.id, position.name)} key={position.id}>
          {position.name}
        </div>
      ))}
    </div>
  )}

  {selectedPosition.length > 0 && (
    <div>
      <h4>New positions for the player:</h4>
      <ul>
        {selectedPosition.map((position) => (
          <li key={position.id}>{position.name}</li>
        ))}
      </ul>
    </div>
  )}
</Grid>
            </Grid>





        </div>
    )
}