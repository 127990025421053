import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import {auth_url} from "../../index";
import Alerts from "../Alerts/Alerts";


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [service, setService] = useState(2)
  const [answer, setAnswer] = useState(false)
  const [message, setMessage] = useState()

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(auth_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          service
        }),
      });

      const data = await response.json()
      if (data.status === "ok") {
        await setAnswer('success')
        await setMessage(data.message)
        await localStorage.setItem('token', data.token)
        await localStorage.setItem('groups', data.groups)
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      } else {
        await setAnswer('error')
        await setMessage(data.message)
      }
    } catch (error) {
      console.error('Error:', error);
    }

  };

  return (
      <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <form onSubmit={handleSubmit}>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        fullWidth
        required
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        fullWidth
        required
      />
      <Button type="submit" variant="contained" color="primary">
        Log In
      </Button>
      {
                                answer !== false ?
                                    <Alerts success={answer} type={'login' } message={message} action={''}/>
                                    :
                                    <div></div>
                            }
    </form>
      </Container>
  );
};

export default Login;
