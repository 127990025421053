import React from "react";
import {Link} from "react-router-dom";


export default function Tools() {
    return (
        <div>
            <Link to={'/merging/'}>
                Merging
            </Link>
            <p>

            </p>
            <Link to={'/connector/'}>
                Data Connector
            </Link>

            <p>

            </p>
            <Link to={'/opta_video_uploader/'}>
                Opta Video Uploader
            </Link>


        </div>
    )
}