import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if(localStorage.getItem('base_url')) {

}
else {
    localStorage.setItem('base_url', '1')
}



export let base_url = 'https://de.smrtstats.com:8111/api/request_on_platform/platform_' + localStorage.getItem('base_url') + '/'
export let base_url_long = 'https://de.smrtstats.com:8111/api/request_on_platform_long/platform_' + localStorage.getItem('base_url') + '/'
export let auth_url = 'https://erp.smrtstats.com:85/api/v1/users/login/'
export let check_token_url = 'https://erp.smrtstats.com:85/api/v1/users/check_token/'
export let logs_url = 'https://erp.smrtstats.com:85/api/v1/action_tracking/save_logs/'
export let opta_url = 'https://demo.smrtstats.com:8771/soccerdata/'
export let media_url = 'https://platform.smrtstats.com:8888'

console.log(base_url)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
