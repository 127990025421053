import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {base_url} from "../../index";
import Grid from "@mui/material/Grid";
import {Button, FormLabel, Pagination, TextField} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import FormControl from "@mui/material/FormControl";
import {BackpackTwoTone} from "@mui/icons-material";
import Alerts from "../Alerts/Alerts";

export default function SquadFormation() {
    const [param, setParam] = useState()
    const [match, setMatch] = useState()
    const [home_team, setHomeTeam] = useState()
    const [away_team, setAwayTeam] = useState()

    const [home_team_formation, setHomeTeamForm] = useState([])
    const [away_team_formation, setAwayTeamForm] = useState([])

    const [search_result, setSearchResult] = useState()
    const [search_await_result, setSearchAwaitResult] = useState()

    const [home_adding, setHomeAdding] = useState(false)

    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    let {id} = useParams()

    useEffect(() => {
        fetchMatch()
    }, [])

    async function fetchSearch(home) {
        if(typeof param !== 'number') {
            const response = await fetch(base_url + 'player/?search=' + param)
            const data = await response.json()
            if (home) {
                await setSearchResult(data.results)
            } else {
                await setSearchAwaitResult(data.results)
            }
        }
    }

    const fetchMatch = async () => {
        const response = await fetch(base_url + 'match/' + id + '/')
        const data = await response.json()
        await setMatch(data)
        await setHomeTeam(data.home_squad)
        await setAwayTeam(data.away_squad)
    }

    const addPlayerToList = (home, id, name) => {
        let a = {
            "player": id,
             "full_name": name
        }

        if(home) {
            setHomeTeamForm(oldArray => [...oldArray, a])
        }
        else {
            setAwayTeamForm(oldArray => [...oldArray, a])
        }
    }

    const updateSquad = async (home) => {
        let sq_id = null
        if (home) {
            sq_id = home_team.id
        } else {
            sq_id = away_team.id
        }
        try {
            const fetchResponse = await fetch(base_url + 'squad_update/' + sq_id + '/', {
                    method: "PATCH",
                    body: JSON.stringify({

                            roster: home ? home_team_formation : away_team_formation
                        }
                    ),
                    headers: {
                        'Content-type': 'application/json'
                    }
            });
            const data = await fetchResponse.json();
            // return data;
            if (data.id) {
                await setAnswer('success')
                await setMessage(data)
                if (home) {
                    await setSearchResult(undefined)
                }
            } else {
                await setAnswer('error')
                await setMessage(data)
            }

        }
        catch (e) {
            console.log(e)
        }
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        // Call the API again with the updated page number
        fetchSearch(param, false, newPage, pageSize);
  };

    return(
        <div>

            <Grid container spacing={3}>

                <Grid item xs={6} md={6}>
                    <div>

                        <FormControl>
                            <FormLabel>
                                Find Player
                            </FormLabel>
                            <TextField onChange={e => setParam(e.target.value)}>

                            </TextField>
                            <Button onClick={() => fetchSearch(true)}>
                                Find Player
                            </Button>
                        </FormControl>

                        <TableContainer>
                            {
                                search_result === undefined ? " " :


                                           <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead>
                                              <TableRow>
                                                <TableCell>Player ID</TableCell>
                                                <TableCell align="left">Full name</TableCell>
                                                <TableCell align="left">Positions</TableCell>
                                                <TableCell align="left">Button</TableCell>
                                              </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {search_result === undefined ? " " :
                                        search_result.map(player => (
                                        <TableRow
                                                  key={player.id}
                                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">
                                                {player.id}
                                            </TableCell>
                                            <TableCell component="th" align="left" scope="row">
                                                <Link to={`/player/${player.id}`}> {player.name} {player.surname}</Link>
                                            </TableCell>
                                            <TableCell align="left">{player.position.map(pos => (pos.name))}</TableCell>
                                            <TableCell align="left">
                                                <Button onClick={() => addPlayerToList(true, player.id,
                                                    player.name + " " + player.surname)}>
                                                    Add Player
                                                </Button>
                                            </TableCell>

                                            {/*<TableCell align="right">{player.field_title}</TableCell>*/}
                                            {/*<TableCell align="right">{player.date}</TableCell>*/}

                                        </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                }
                            </TableContainer>
                        <TableContainer>
                            {
                                home_team_formation == false ? " " :


                                           <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead>
                                              <TableRow>
                                                <TableCell>Player ID</TableCell>
                                                <TableCell align="left">Full name</TableCell>
                                              </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {home_team_formation == false ? " " :
                                        home_team_formation.map(player => (
                                        <TableRow
                                                  key={player.id}
                                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">
                                                {player.player}
                                            </TableCell>
                                            <TableCell component="th" align="left" scope="row">
                                                <Link to={`/player/${player.player}`}> { player.full_name } </Link>
                                            </TableCell>

                                            {/*<TableCell align="right">{player.field_title}</TableCell>*/}
                                            {/*<TableCell align="right">{player.date}</TableCell>*/}

                                        </TableRow>
                                        ))}
                                        <Button onClick={() => updateSquad(true)}>
                                            Update Squad
                                        </Button>

                                    </TableBody>

                                </Table>
                                }
                        </TableContainer>

                    </div>
                </Grid>

                <Grid item xs={6} md={6}>
                    <div>

                        <FormControl>
                            <FormLabel>
                                Find Player
                            </FormLabel>
                            <TextField onChange={e => setParam(e.target.value)}>

                            </TextField>
                            <Button onClick={() => fetchSearch(false)}>
                                Find Player
                            </Button>
                        </FormControl>

                        <TableContainer>
                            {
                                search_await_result === undefined ? " " :


                                           <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead>
                                              <TableRow>
                                                <TableCell>Player ID</TableCell>
                                                <TableCell align="left">Full name</TableCell>
                                                <TableCell align="left">Positions</TableCell>
                                                <TableCell align="left">Button</TableCell>
                                              </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {search_await_result === undefined ? " " :
                                        search_await_result.map(player => (
                                        <TableRow
                                                  key={player.id}
                                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">
                                                {player.id}
                                            </TableCell>
                                            <TableCell component="th" align="left" scope="row">
                                                <Link to={`/player/${player.id}`}> {player.name} {player.surname}</Link>
                                            </TableCell>
                                            <TableCell align="left">{player.position.map(pos => (pos.name))}</TableCell>
                                            <TableCell align="left">
                                                <Button onClick={() => addPlayerToList(false, player.id,
                                                    player.name + " " + player.surname)}>
                                                    Add Player
                                                </Button>
                                            </TableCell>

                                            {/*<TableCell align="right">{player.field_title}</TableCell>*/}
                                            {/*<TableCell align="right">{player.date}</TableCell>*/}

                                        </TableRow>

                                        ))}
                                    </TableBody>

                                </Table>

                                }

                            </TableContainer>
                        <TableContainer>
                            {
                                away_team_formation == false ? " " :


                                           <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                    <TableHead>
                                              <TableRow>
                                                <TableCell>Player ID</TableCell>
                                                <TableCell align="left">Full name</TableCell>
                                              </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {away_team_formation == false ? " " :
                                        away_team_formation.map(player => (
                                        <TableRow
                                                  key={player.player}
                                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">
                                                {player.player}
                                            </TableCell>
                                            <TableCell component="th" align="left" scope="row">
                                                <Link to={`/player/${player.id}`}> { player.full_name } </Link>
                                            </TableCell>

                                            {/*<TableCell align="right">{player.field_title}</TableCell>*/}
                                            {/*<TableCell align="right">{player.date}</TableCell>*/}

                                        </TableRow>
                                        ))}
                                        <Button onClick={() => updateSquad(false)}>
                                            Update Squad
                                        </Button>

                                    </TableBody>
                                </Table>
                                }
                        </TableContainer>

                    {/*<div>*/}

                    {/*    {*/}
                    {/*    home_team === undefined ? " 1 " :*/}
                    {/*        <TableContainer>*/}

                    {/*            <TextField>*/}

                    {/*            </TextField>*/}

                    {/*            <Button>*/}
                    {/*                Find Player*/}
                    {/*            </Button>*/}

                    {/*            <p>*/}

                    {/*            </p>*/}

                    {/*            <Button>*/}
                    {/*                Update Squad*/}
                    {/*            </Button>*/}


                    {/*}*/}

                    {/*</div>*/}
                    </div>
                </Grid>
                                   {
                                answer !== false ?
                                    <Alerts success={answer} type={'squad'} message={message} action={'updated'}/>
                                    :
                                    <div></div>
                            }
            </Grid>


        </div>
    )


}