import {Link} from "react-router-dom";

export default function DataConnector() {
    return (
        <div>
            <Link to={'/connector/comp_connect/'}>
                Competition Connector (not avlbl yet)
            </Link>
            <p></p>
            <Link to={'/connector/team_connect/'}>
                Team Connector
            </Link>
            <p>

            </p>
             <Link to={'/connector/player_connect/'}>
                Player Connector
            </Link>

        </div>
    )
}