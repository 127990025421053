import {logs_url} from "../index";

const action_tracker = async (entity_type, entity_id) => {
  try {
    const token = localStorage.getItem('token')
    const response = await fetch(`${logs_url}?entity_type=${entity_type}&entity_id=${entity_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `token ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('.');
    }

    const data = await response.json();
    return data

  } catch (error) {
    console.error(':', error);
  }
};

export default action_tracker;