import React, {useState, useEffect} from "react";
import {Link, useParams} from 'react-router-dom'
import {base_url, base_url_long} from "../../index";
import {
    Avatar,
    Button, Checkbox,
    CircularProgress, FormControlLabel,
    FormLabel,
    List,
    ListItem, ListItemText,
    Select,
    TablePagination,
    TextField
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Alerts from "../Alerts/Alerts";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import save_logs from "../../tools/save_logs";


export default function Season() {
    const { id } = useParams()
    const [season, setSeason] = useState()
    const [matches, setMatches] = useState([])
    const [query, setQuery] = useState('')
    const [techMatches, setTechMathes] = useState('')
    const [teamsUpdated, setTeamsUpdated] = useState([])
    const [teamSearchResult, setTeamSearchResult] = useState([])
    const [isInitialSearch, setIsInitialSearch] = useState(true);
    const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
    const [standartStadium, setStandartStadium] = useState(true)
  const [selectedStadium, setSelectedStadium] = useState(null);
    const [editing, setEditing] = useState(false)
    const [openTeamList, setOpenTeamList] = useState(true)
    const [page, setPage] = useState(1)
    const [selectedTeamFromFirstGrid, setSelectedTeamFromFirstGrid] = useState(null);
    const [selectedTeamFromSecondGrid, setSelectedTeamFromSecondGrid] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [param, setParams] = useState()
    const [loading, setLoading] = useState(false);
    const [matchId, setMatchId] = useState('');


    const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // Add 1 to match the current page number for API call
  };

    const handleTeamSelectFromFirstGrid = (team) => {
        console.log(team)
            setSelectedTeamFromFirstGrid(team);
            // setSelectedStadium(team.fields[0])
        };


        const handleTeamSelectFromSecondGrid = (team) => {
            setSelectedTeamFromSecondGrid(team);
        };

    const handleSeason = async () => {
        try {
            const response = await fetch(`${base_url}season/${id}`)
            const data = await response.json()
            setSeason(data)
            setTeamsUpdated(data.teams)
        }
        catch (e) {
            console.error("Error handle season: ", e)
        }
    }

    const fetchTeam = async () => {
        const response = await fetch(base_url + "team/?search=" + param)
        const data = await response.json()
        await setTeamSearchResult(data.results)
        console.log(teamSearchResult)
    }

    const handleSearch = async (query) => {
    try {
      const response = await fetch(`${base_url}field/?search=${query}`);
      const data = await response.json();
      setSearchResults(data.results);
    } catch (error) {
      console.error('Error searching stadiums:', error);
      setSearchResults([]);
    }
  };

  const handleSearchTextChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    handleSearch(newSearchText);
  };

  const handleStadiumSelect = (stadium) => {
    setSelectedStadium(stadium);
  };

    const AddTeamToSeason = async () => {
        try {
            const fetchResponse = await fetch(
                base_url + 'season/' + id + '/', {
                    method: "PATCH",
                    headers: {
                        'Content-Type': "application/json",
                    },
                    body: JSON.stringify({
                        "teams": teamsUpdated.map((team => team.id))
                    })
                }
            )

            const data = await fetchResponse.json()
            if (data.id) {
                await setTeamSearchResult([])
                await handleSeason()
                await setEditing(false)
            } else {

            }
        }
        catch (e) {
            console.log(1)
            return e
        }
    }


    async function createLineup(team, isHome) {
          const endpoint = base_url + 'squad_create/';
          const payload = {
              team: team.id,
                is_home: isHome,
                roster: []
          };
          try {
            const response = await fetch(endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });

            if (!response.ok) {
              throw new Error('Error creating lineup');
            }

            const data = await response.json();
            return data;
          } catch (error) {
            console.error('Error creating lineup:', error);
            throw error;
          }
}

  async function createMatch() {
              try {
                  setLoading(true)
                // Create the first lineup (is_home = true)
                const lineup1 = await createLineup(selectedTeamFromFirstGrid, true);
                const lineup1Id = lineup1.id;

                // Create the second lineup (is_home = false)
                const lineup2 = await createLineup(selectedTeamFromSecondGrid, false);
                const lineup2Id = lineup2.id;

                const endpoint = base_url + 'match/';
                const payload = {
                    date: selectedDate,
                    home_squad: lineup1Id,
                    away_squad: lineup2Id,
                    season: season.id,
                    field: selectedStadium !== null ? selectedStadium.id : selectedTeamFromFirstGrid.fields[0].id
                };

                const response = await fetch(endpoint, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(payload),
                });

                if (!response.ok) {
                  throw new Error('Error creating match');
                }

                const data = await response.json();
                  // await fetchMatches(seasonId, 1)
                  if (data.id) {
                            await setAnswer('success')
                            await setMessage(data)
                      await save_logs('match', data.id, 'create',
                          selectedTeamFromFirstGrid + ' ' + selectedTeamFromSecondGrid)
                        } else {
                            await setAnswer('error')
                            await setMessage(data)
                        }
                return data;
              } catch (error) {
                console.error('Error creating match:', error);
                throw error;
              }finally {
                  setLoading(false)
              }
    }

    const handleMatches = async () => {
        try {
            const response = await fetch(`${base_url}match/?season=${id}&page=${page}&search=${query}`)
            const data = await response.json()
            setMatches(data.results)
            setTechMathes(data.count)
            if (isInitialSearch) {
                setIsInitialSearch(false); // Set isInitialSearch to false after first successful search
    }
        } catch (error) {
            console.error("Error handle matches: ", error)
        }

    }

    const handleRemoveTeam = (teamId) => {
        const updatedTeams = teamsUpdated.filter(team => team.id !== teamId);
        setTeamsUpdated(updatedTeams);
    };

    const handleAddTeam = (newTeam) => {
        setTeamsUpdated(prevTeams => [...prevTeams, newTeam]);
    };

    const handleCheckboxChange = (event) => {
        setStandartStadium(event.target.checked);
  };

    useEffect(() => {
        handleSeason()
        handleMatches()
  }, [page]);

    return (

        <div>

            {season && (
                <div style={{marginTop: 25}}>
                  <img src={season.competition_logo} alt="Competition Logo" height={20} />
                  <img src={season.country_flag} alt="Country Flag" height={20}/>
                  <h2>{season.title} – {season.year_season.years}</h2>
                    {
                        editing === true ? "" :
                            <Button onClick={() => setEditing(true)}>
                                Edit the list of teams
                            </Button>
                    }

                </div>
            )}

            {
                editing === true ?
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {
                                    teamsUpdated == false ? "" : teamsUpdated.map((team) => (
                                        <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                                            <Avatar alt={team.name} src={team.logo}/>
                                            <Typography variant="body1" component="p"
                                                        style={{marginLeft: '8px', fontSize: 14}}
                                            >
                                                {team.id} {team.name}
                                            </Typography>
                                            <Button onClick={() => handleRemoveTeam(team.id)}> ❌</Button>
                                        </div>
                                    ))
                                }
                                <div>
                                    <Button onClick={() => AddTeamToSeason()}> Save </Button>
                                    <Button
                                        onClick={() => (setTeamsUpdated(season.teams), setEditing(false), setTeamSearchResult([]))}> Cancel </Button>
                                </div>
                                <div>

                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField label="Type team name" onChange={e => setParams(e.target.value)}/>
                                <Button onClick={() => fetchTeam()}> Find Team </Button>
                                {
                                    teamSearchResult == false ? "" : teamSearchResult.map((team) => (
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '8px',
                                            marginTop: 10
                                        }}>
                                            <Avatar alt={team.name} src={team.logo}/>
                                            <Typography variant="body1" component="p"
                                                        style={{marginLeft: '8px', fontSize: 14}}
                                            >
                                                {team.name} ({team.id})
                                            </Typography>
                                            <Button onClick={() => handleAddTeam(team)}> ➕</Button>
                                        </div>
                                    ))
                                }
                            </Grid>
                        </Grid>
                    </div>
                    :

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <h4>Select Home team for the future match</h4>
                            {season === undefined ? "" : season.teams.map((team) => (
                                <>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                                        <Avatar alt={team.name} src={team.logo}/>
                                        <Typography variant="body1" component="p"
                                                    style={{marginLeft: '8px', fontSize: 14}}
                                        >
                                            {team.name}
                                        </Typography>
                                        <Button onClick={() => handleTeamSelectFromFirstGrid(team)}> ➕ </Button>
                                    </div>
                                    <hr style={{borderTop: '0.5px solid #ccc', margin: '8px 0'}}/>
                                </>
                            ))}
                        </Grid>

                        <Grid item xs={4}>
                            <h4>Select Away team for the future match</h4>
                            {season === undefined ? "" : season.teams.map((team, index) => (
                                <div key={team.id}>
                                    {selectedTeamFromFirstGrid && selectedTeamFromFirstGrid.id === team.id ? null : (
                                        <>
                                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                                                <Avatar alt={team.name} src={team.logo}/>
                                                <Typography variant="body1" component="p"
                                                            style={{marginLeft: '8px', cursor: 'pointer', fontSize: 14}}
                                                            onClick={() => handleTeamSelectFromSecondGrid(team)}>
                                                    {team.name}
                                                </Typography>
                                                <Button
                                                    onClick={() => handleTeamSelectFromSecondGrid(team)}> ➕ </Button>
                                            </div>
                                            <hr style={{borderTop: '0.5px solid #ccc', margin: '8px 0'}}/>
                                        </>

                                    )}
                                </div>
                            ))}
                        </Grid>
                        <Grid item xs={4}>
                            <h4>Select date and place of the Match</h4>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {selectedTeamFromFirstGrid && (
                                    <div style={{display: 'flex', alignItems: 'center', marginRight: '8px'}}>
                                        <Avatar alt={selectedTeamFromFirstGrid.name}
                                                src={selectedTeamFromFirstGrid.logo}/>
                                        <Typography variant="body1" component="p" style={{marginLeft: '8px'}}>
                                            {selectedTeamFromFirstGrid.name}
                                        </Typography>
                                    </div>
                                )}

                                –

                                {selectedTeamFromSecondGrid && (
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Typography variant="body1" component="p" style={{marginLeft: '8px'}}>
                                            {selectedTeamFromSecondGrid.name}
                                        </Typography>
                                        <Avatar alt={selectedTeamFromSecondGrid.name}
                                                src={selectedTeamFromSecondGrid.logo}/>
                                    </div>
                                )}
                            </div>

                            <Grid container spacing={2} style={{marginTop: 10}}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Choose date"
                                        type="date"
                                        defaultValue={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {selectedTeamFromFirstGrid && selectedTeamFromFirstGrid.fields && selectedTeamFromFirstGrid.fields[0]?.id ? (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={standartStadium}
                                                    onChange={handleCheckboxChange}
                                                    name="standartStadium"
                                                />
                                            }
                                            label="Is Standard Stadium (from home team)"
                                        />
                                    ) : (
                                        <div>
                                            <p>There is no home field listed in the team profile.</p>
                                            <div>
                                                <TextField
                                                    label="Choose stadium"
                                                    type="text"
                                                    value={searchText}
                                                    onChange={handleSearchTextChange}
                                                />

                                                <List>
                                                    {searchResults.map((stadium) => (
                                                        <ListItem
                                                            key={stadium.id}

                                                            onClick={() => handleStadiumSelect(stadium)}
                                                        >
                                                            <ListItemText primary={stadium.title}/>
                                                        </ListItem>
                                                    ))}
                                                </List>

                                                {selectedStadium && (
                                                    <div>
                                                        <h2>Choose Stadium:</h2>
                                                        <p>Name: {selectedStadium.title}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}


                                    {
                                        standartStadium ? '' :
                                            <div>
                                                <TextField
                                                    label="Choose stadium"
                                                    type="text"
                                                    value={searchText}
                                                    onChange={handleSearchTextChange}
                                                />

                                                <List>
                                                    {searchResults.map((stadium) => (
                                                        <ListItem
                                                            key={stadium.id}

                                                            onClick={() => handleStadiumSelect(stadium)}
                                                        >
                                                            <ListItemText primary={stadium.title}/>
                                                        </ListItem>
                                                    ))}
                                                </List>

                                                {selectedStadium && (
                                                    <div>
                                                        <h2>Choose Stadium:</h2>
                                                        <p>Name: {selectedStadium.title}</p>
                                                    </div>
                                                )}
                                            </div>
                                    }


                                </Grid>
                            </Grid>

                            <Button onClick={() => createMatch()}>
                                Add match
                            </Button>
                            {loading ?

                                <CircularProgress/>
                                :
                                answer !== false ?
                                    <Alerts success={answer} type={'match'} message={message} action={'added'}/>
                                    :
                                    <div></div>
                            }
                        </Grid>

                    </Grid>
            }

            <TextField onChange={e => setQuery(e.target.value)} label="type name of team"/>
            <Button onClick={() => handleMatches()}>Find match</Button>

            <div>
           {
                matches == false ? " There are no such matches this Season "
                    :
                    <div style={{marginTop: 15}}>

                        <TableContainer component={Paper}>
          <Table>

            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Home Team</TableCell>
                <TableCell>Away Team</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Video</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches === undefined ? '' : matches.map((match) => (
                <TableRow key={match.id}>

                  <TableCell>
                    <Link to={`/match/${match.id}`}>
                      {match.id}
                    </Link>
                  </TableCell>
                    <TableCell>
                    <Link to={`/team/${match.home_team_id}`}>
                      {match.home_team}
                    </Link>
                  </TableCell>
                     <TableCell>
                    <Link to={`/team/${match.away_team_id}`}>
                      {match.away_team}
                    </Link>
                  </TableCell>

                    <TableCell>
                        {match.date}
                    </TableCell>

                     <TableCell>
                        {match.videos == false ? <div>
                            <a href={"https://video3.smrtstats.com:85/" + match.id}> Add video</a>
                        </div> : <Link to={`/video/${match.id}`}> video </Link>}
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={techMatches}
            page={page - 1}
            rowsPerPage={10}
            onPageChange={handleChangePage}
          />
        </TableContainer>
                    </div>
            }
        </div>
        </div>
    )
}