import {Link} from "react-router-dom";

export default function OtherData() {
    return (
        <div>
            <Link to={'/stadium/'}>
                Stadium
            </Link>
        </div>
    )
}