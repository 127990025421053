import {Link} from "react-router-dom";

export default function Merging() {
    return (
        <div>

            <Link to={'/merge_players/'}>
                Players merging
            </Link>
            <p>

            </p>
                <Link to={'/merge_teams/'}>
                Teams merging
            </Link>

        </div>
    )
}