import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


export default function Alerts(success) {
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            {
                success.success === "success" ?
                    <Alert severity="success">The {success.type} <b> {success.message.name} </b> is successfully {success.action}.
                        You can add the next</Alert>
                :
                    <Alert severity="error">The {success.type} is not {success.action}, because of error occurred:
                        {JSON.stringify(success.message)} </Alert>

            }
        </Stack>
    )
}