import FormControl from "@mui/material/FormControl";
import {Button, Checkbox, CircularProgress, FormLabel, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {useState, ChangeEvent} from "react";
import Alerts from "../Alerts/Alerts";
import {base_url} from "../../index";
import Grid from "@mui/material/Grid";

export default function AddCompetitionForm() {
    const [name, setName] = useState("")
    const [short_name, setShortName] = useState("")
    const [format, setFormat] = useState("")
    const [logoFile, setLogoFile] = useState(null)


    const [add, setAdd] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState()

    const [nationalityFound, setNationalityFound] = useState([])
    const [natQuerySearch, setNatQuerySearch] = useState('')
    const [natChoose, setNatChoose] = useState({id: null, name: null})

    const handleNationalitySearchChange = async (event) => {
      const { value } = event.target;
      setNatQuerySearch(value);

      try {
        const response = await fetch(base_url + 'nationality/?search=' + value);
        const data = await response.json();
        setNationalityFound(data.results);
      } catch (error) {
        console.error('Error searching nationalities:', error);
      }
    };

    const addTournament = async () => {

        const formData = new FormData()
        formData.append("name", name)
        formData.append("short_name", short_name)
        formData.append("competition_format", format)
        formData.append("country", natChoose.id)

        if(logoFile) {
            formData.append('logo', logoFile)
        }

        // formData.append("logo", logoFile)



        try {
            setLoading(true)
            const fetchResponse = await fetch(base_url + 'competition/', {
                    method: "POST",
                    body: formData,
            });
            const data = await fetchResponse.json();
             if (data.id) {
                await setAnswer('success')
                await setMessage(data)
            } else {
                await setAnswer('error')
                await setMessage(data)
            }
            console.log(data)
            // await setName("success")
            // return data;


        }
        catch (e) {
            // await setName("error")
        }
        finally {
            setLoading(false)
        }
    }

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        setLogoFile(file);
  };

    return (
        <Grid container spacing={2} style={{marginTop: 35}}>
            <Grid item xs={6}>
        <FormControl>
                <FormLabel>Enter name of competition</FormLabel>
                    <TextField type="text" required
                    onChange={e => setName(e.target.value)}
                    >

                    </TextField>

            <FormLabel>Enter shortname of competition</FormLabel>
            <TextField type="text"
            onChange={e => setShortName(e.target.value)}></TextField>

            <TextField
                label="Search Country"
                name="nationalitySearch"
                fullWidth
                value={natQuerySearch}
                onChange={handleNationalitySearchChange}
                style={{ marginTop: 5}}
            />

            <FormLabel>Select the tournament format (mandatory for regular)</FormLabel>
            <Select value={format} onChange={e => setFormat(e.target.value)}>
                <MenuItem value={1}>
                    Autumn-Spring
                </MenuItem>
                <MenuItem value={2}>
                    Spring-Autumn
                </MenuItem>
            </Select>

            <input type="file" accept="image/*" onChange={handleLogoChange} />


            <Button style={{marginTop: 45}} onClick={() => addTournament()}>Submit</Button>

            {
                  loading ?

                                        <CircularProgress />
                                        :
                                answer !== false ?
                                    <Alerts success={answer} type={'Competition'} message={message} action={'added'}/>
                                    :
                                    <div></div>
                            }

        </FormControl>
            </Grid>
            <Grid item xs={6}>
                {nationalityFound.length > 0 && (
                    <div>
                      <h4>Nationalities:</h4>
                      {nationalityFound.map((nationality) => (
                        <div style={{ marginTop: 20}}
                             key={nationality.id}
                            onClick={() => setNatChoose({
                                id: nationality.id,
                                name: nationality.name,
                                flag: nationality.flag
                            })}>
                            <img style={{width: 15, height: 10}}  src={nationality.flag} alt={' '} /> {nationality.name}
                        </div>
                      ))}


                          <div>
                            <h4>
                            You choose:
                            </h4>
                            <div>
                                <img style={{width: 15, height: 10}}  src={natChoose.flag === null ? ' ' : natChoose.flag} alt={' '} />

                                    {
                                        natChoose.name === null ? ' ' : ' ' + natChoose.name
                                    }
                            </div>
                        </div>



                    </div>
  )}
            </Grid>
        </Grid>
    )
}