import React, {useState, useEffect} from "react";
import {Link, useParams} from 'react-router-dom'
import {base_url} from "../../index";
import {Avatar, Button, CircularProgress, FormLabel, Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Alerts from "../Alerts/Alerts";
import FormControl from "@mui/material/FormControl";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Grid from "@mui/material/Grid";


export default function Competition() {
    const [result, setResult] = useState()
    const [edit, setEdit] = useState(false)
    const [add, setAdd] = useState(false)
    const [name, setName] = useState('')
    const [short_name, setShortName] = useState('')
    const [format, setFormat] = useState()
    const [country, setCountry] = useState()
    const [status, setStatus] = useState()
    const [years_list, setYearList] = useState([])
    const [req, setReq] = useState()
    const [year, setYear] = useState()
    const [nationalityFound, setNationalityFound] = useState([])
    const [natQuerySearch, setNatQuerySearch] = useState('')
    const [natChoose, setNatChoose] = useState({id: null, name: null})
    const [logoFile, setLogoFile] = useState(null)


    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompetition()
    }, [])

    let { id } = useParams()

    const fetchCompetition = async () => {
        const response = await fetch(base_url + 'competition/' + id)
        const data = await response.json()
        await setResult(data)
        await fetchCountry(data)
    }


    const handleNationalitySearchChange = async (event) => {
      const { value } = event.target;
      setNatQuerySearch(value);

      try {
        const response = await fetch(base_url + 'nationality/?search=' + value);
        const data = await response.json();
        setNationalityFound(data.results);
      } catch (error) {
        console.error('Error searching nationalities:', error);
      }
    };

    const fetchCountry = async (data) => {

        console.log(data)
        const country_id = data.country

      try {
        const response = await fetch(base_url + 'nationality/' + country_id);
        const data = await response.json();
        console.log(data)
        await setCountry(data);
      } catch (error) {
        console.error('Error searching nationalities:', error);
      }
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        setLogoFile(file);
  };


    const editCompetition = async () => {

        const formData = new FormData()
        formData.append("name", name.length === 0 ? result.name : name)
        formData.append("short_name", short_name.length === 0 ? result.short_name : short_name )
        formData.append("competition_format", format === undefined ? result.competition_format : format)
        formData.append("country", natChoose.id === null ? result.country : natChoose.id)

        if(logoFile) {
            formData.append('logo', logoFile)
        }

        try {
            setLoading(true)
            const fetchResponse = await fetch(
                base_url + 'competition/' + id + '/', {
                    method: "PATCH",
                    body: formData
                }
            )
            const data = await fetchResponse.json()
            await fetchCompetition()
            console.log(data)
            if (data.id) {
                await setAnswer('success')
                await setMessage(data)
            } else {
                await setAnswer('error')
                await setMessage(data)
            }
            console.log(data)
        }
        catch (e) {
            console.log(1)
                return e
        }
        finally {
            setLoading(false)
        }
    }

    const searchYear = async () => {
        const response = await fetch(base_url + 'year_season/?search=' + req)
        const data = await response.json()
        await setYearList(data.results)
    }

    const createNewSeason = async () => {
        try {
            const fetchResponse = await fetch(
                base_url + 'season/', {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json",
                    },
                    body: JSON.stringify({
                        "competition": id,
                        "year_season": year.year_id,
                        "title": year.name + " " + result.name,
                        "short_title": year.name + " " + result.short_name
                        // "name": name,
                        // "short_name": short_name,
                        // "competition_format": format
                    })
                }
            )
            const data = await fetchResponse.json()
             if (data.id) {
                await setAnswer('success')
                await setMessage(data)
            } else {
                await setAnswer('error')
                await setMessage(data)
            }
            await setStatus("success")
            await fetchCompetition()
            console.log(data)
        }
        catch (e) {
            await setStatus("error")
            return e
        }
    }




    console.log(country)

    return (
        <div>
            {
                result === undefined ? " " :
                    <div>
                       <p style={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar style={{ width: 50, height: 50, marginRight: 10 }} alt={result.name} src={result.logo} />
                               <Avatar style={{width: 50, height: 50, marginRight: 10}} alt={country === undefined ? "" : country.name}
                                src={country === undefined ? "" : country.flag} />
                              Information about {result.name} Competition
                        </p>

                        <Button onClick={() => (setEdit(true), setAdd(false))}>
                            Edit Competition
                        </Button>
                        <Button onClick={() => (setEdit(false), setAdd(true))}>
                            Add Season to Competition
                        </Button>
                    </div>

            }

            {edit === false ? ""
                :
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                <FormControl>
                <FormLabel>Enter name of competition</FormLabel>
                    <TextField type="text" required defaultValue={result.name}
                    onChange={e => setName(e.target.value)}
                    >

                    </TextField>

            <FormLabel>Enter shortname of competition</FormLabel>
            <TextField type="text"
            onChange={e => setShortName(e.target.value)} defaultValue={result.short_name}
            ></TextField>

                    <TextField
                label="Search Country"
                name="nationalitySearch"
                fullWidth
                value={natQuerySearch}
                onChange={handleNationalitySearchChange}
                style={{ marginTop: 5}}
            />



            <FormLabel>Select the tournament format (mandatory for regular)</FormLabel>
            <Select
                onChange={e => setFormat(e.target.value)}
            >
                <MenuItem value={1}>
                    Autumn-Spring
                </MenuItem>
                <MenuItem value={2}>
                    Spring-Autumn
                </MenuItem>
            </Select>

            <input type="file" accept="image/*" onChange={handleLogoChange} />

            <Button
                onClick={() => editCompetition()}
            >
                Submit</Button>
                    {
                          loading ?

                                        <CircularProgress />
                                        :
                                answer !== false ?
                                    <Alerts success={answer} type={'Competition'} message={message} action={'edited'}/>
                                    :
                                    <div></div>
                            }


        </FormControl>
                </Grid>
                    <Grid item xs={6}>
                        {nationalityFound.length > 0 && (
                    <div>
                      <h4>Nationalities:</h4>
                      {nationalityFound.map((nationality) => (
                        <div style={{ marginTop: 20}}
                             key={nationality.id}
                            onClick={() => setNatChoose({
                                id: nationality.id,
                                name: nationality.name,
                                flag: nationality.flag
                            })}>
                            <img style={{width: 15, height: 10}}  src={nationality.flag} alt={' '} /> {nationality.name}
                        </div>
                      ))}


                          <div>
                            <h4>
                            You choose:
                            </h4>
                            <div>
                                <img style={{width: 15, height: 10}}  src={natChoose.flag === null ? ' ' : natChoose.flag} alt={' '} />

                                    {
                                        natChoose.name === null ? ' ' : ' ' + natChoose.name
                                    }
                            </div>
                        </div>



                    </div>
  )         }
                    </Grid>
                </Grid>
            }

            {
                add === false ? " "
                    :
                    <div>
                        <div>
                                        {result.seasons == false ? " " :

                                            <div>
                                                <h3>
                                                    We already have the following Seasons of this tournament:
                                                </h3>
                                                 <Table>
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell>Tournament ID</TableCell>
                                                        <TableCell>Tournament Title </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                     <TableBody>

                                                        {result.seasons.map(season => (

                                                            <TableRow key={season.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell align="left">{season.id}</TableCell>
                                                                  <TableCell component="th" align="left" scope="row">
                                                                      <Link to={`/season/${season.id}`}>season id - {season.id},
                                                                                season title - {season.title} - {season.year_season}
                                                                            </Link>
                                                                  </TableCell>
                                                             </TableRow>

                                                        ))}
                                                    </TableBody>
                                                </Table>

                                                <p>

                                                </p>
                                                <p>
                                                    And you can add new, please, use next form:
                                                </p>
                                            </div>
                                        }
                                    </div>
                        <FormControl>
                            <FormLabel>Type years of season to adding new Season</FormLabel>
                                <TextField onChange={e => setReq(e.target.value)}>

                                </TextField>
                            <Button onClick={() => searchYear()}>
                                To Find Year
                            </Button>
                        </FormControl>

                        <h4>Please, select year for season </h4>
                        {
                            years_list == false ? " "
                                :
                                <div>
                                    {
                                        years_list.map(year => (
                                            <p onClick={() => setYear({year_id: year.id, name: year.years})}>{year.years}</p>
                                        ))
                                    }
                                </div>
                        }

                        {
                            year === undefined ? "" :
                                <div>
                                    <h4>
                                        You choose {year.name}.
                                    </h4>
                                    <p>
                                        Do you want to begin "{result.name}" of {year.name} ?
                                    </p>
                                    <Button onClick={() => createNewSeason()}>
                                        Yes, begin new season
                                    </Button>

                            {
                                  loading ?

                                        <CircularProgress />
                                        :
                                answer !== false ?
                                    <Alerts success={answer} type={'Tournament (Season)'} message={message} action={'added'}/>
                                    :
                                    <div></div>
                            }
                                </div>
                        }
                    </div>

            }
        </div>
    )
}