import {base_url, base_url_long} from "../../index";
import {Button} from "@mui/material";

export default function OptaVideoUploader() {

    async function uploadVideoForMatches() {
        const allMatches = []
        let page = 1

        while (true) {
            const url = `${base_url_long}ftb_api_connector/match_entities/?page=${page}`
            try {
                const response = await fetch(url);
                const data = await response.json();
                allMatches.push(...data.results);
                if (!data.next) {
                    break;
                }

                console.log(page)
                page++
            } catch (error) {
                console.error('Ошибка при загрузке матчей:', error);
                break;
            }
        }

        const filteredMatches = []
        const today = new Date()

        for (const match of allMatches) {
        const matchUrl = `${base_url}match/${match.match}`

        try {
            const response = await fetch(matchUrl)
            const matchDetails = await response.json()

            const matchDate = new Date(matchDetails.date)

            if (Array.isArray(matchDetails.videos) && matchDetails.videos.length === 0 && matchDate < today) {
                filteredMatches.push(matchDetails)
            }
        } catch (error) {
            console.error(`Ошибка при загрузке деталей матча с ID ${match.match}:`, error)
        }
    }
        const matchedMatches = allMatches.filter(match =>
        filteredMatches.some(filteredMatch => filteredMatch.id === match.id)
        );

        console.log(matchedMatches)

        // const filteredMatches = allMatches.filter(match => {
        //     const matchDate = new Date(match.date);
        //     return Array.isArray(match.videos) && match.videos.length === 0 && matchDate < today;
        // });
        //
        // console.log(filteredMatches)
    }

    return (
        <div>
                <Button onClick={() => uploadVideoForMatches()}>
                        Пукич
                </Button>
            </div>
    )
}