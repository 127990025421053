import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
    Avatar,
    Button,
    Card, CardContent, CardMedia, CircularProgress,
    FormLabel,
    List,
    ListItem,
    ListItemText,
    Pagination,
    TablePagination,
    TextField
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {base_url} from "../../index";
import {Form, json, Link, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Alerts from "../Alerts/Alerts";
import Typography from "@mui/material/Typography";



function Matches() {
    const history = useNavigate()
    const [query, setQuery] = useState('');
    const [seasons, setSeasons] = useState([]);
    const [matches, setMatches] = useState([])
    const [selectedSeasonId, setSelectedSeasonId] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null)
    const [teams, setTeams] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);

    const [mPage, setMPage] = useState(1)
    const [rowsMPerPage, setRowsMPerPage] = useState(10)

    const [isInitialSearch, setIsInitialSearch] = useState(true);

    const [selectedTeamFromFirstGrid, setSelectedTeamFromFirstGrid] = useState(null);
    const [selectedTeamFromSecondGrid, setSelectedTeamFromSecondGrid] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()

    const handleSearch = async () => {
        try {
            setLoading(true)
          const response = await fetch(`${base_url}season/?search=${query}`);
          const data = await response.json();
          setSeasons(data);
          setSelectedSeasonId(null); // Reset selected season when new search is performed
          setTeams([]); // Clear teams when a new search is performed
            setSelectedSeason(null);
        } catch (error) {
          console.error('Error searching seasons:', error);
        } finally {
            setLoading(false);
        }
  };

    const handleMatches = async (season, page) => {
        try {
            const response = await fetch(`${base_url}match/?season=${season}&page=${page}`)
            const data = await response.json()
            setMatches(data.results)
            if (isInitialSearch) {
                setIsInitialSearch(false); // Set isInitialSearch to false after first successful search
    }
        } catch (error) {
            console.error("Error handle matches: ", error)
        }

    }

    console.log(matches)

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

    const handleMChangePage = (event, newPage) => {
            setMPage(newPage + 1);

        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

         const handleMChangeRowsPerPage = (event) => {
            setRowsMPerPage(parseInt(event.target.value, 10));
            setMPage(0);
        };

        const handleTeamSelectFromFirstGrid = (team) => {
            setSelectedTeamFromFirstGrid(team);
        };

        const handleTeamSelectFromSecondGrid = (team) => {
            setSelectedTeamFromSecondGrid(team);
        };

      async function createLineup(team, isHome) {
          const endpoint = base_url + 'squad_create/';
          const payload = {
              team: team.id,
                is_home: isHome,
                roster: []
          };
          try {
            const response = await fetch(endpoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });

            if (!response.ok) {
              throw new Error('Error creating lineup');
            }

            const data = await response.json();
            return data;
          } catch (error) {
            console.error('Error creating lineup:', error);
            throw error;
          }
}

  async function createMatch() {
              try {
                // Create the first lineup (is_home = true)
                const lineup1 = await createLineup(selectedTeamFromFirstGrid, true);
                const lineup1Id = lineup1.id;

                // Create the second lineup (is_home = false)
                const lineup2 = await createLineup(selectedTeamFromFirstGrid, false);
                const lineup2Id = lineup2.id;

                const endpoint = base_url + 'match/';
                const payload = {
                    date: selectedDate,
                    home_squad: lineup1Id,
                    away_squad: lineup2Id,
                    season: selectedSeason.id,
                    field: 1
                };

                const response = await fetch(endpoint, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(payload),
                });

                if (!response.ok) {
                  throw new Error('Error creating match');
                }

                const data = await response.json();
                  // await fetchMatches(seasonId, 1)
                  if (data.id) {
                            await setAnswer('success')
                            await setMessage(data)
                        } else {
                            await setAnswer('error')
                            await setMessage(data)
                        }
                return data;
              } catch (error) {
                console.error('Error creating match:', error);
                throw error;
              }
    }
//

  useEffect(() => {
  if (!isInitialSearch) {
      handleMatches(page);
    }
  }, [page, isInitialSearch])


    return (
    <div>
      <TextField label="Find Season" variant="outlined" value={query} onChange={(e) => setQuery(e.target.value)} />
      <Button onClick={handleSearch}>Find Season</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell>CS</TableCell>*/}
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Competition Logo</TableCell>
              <TableCell>Country Flag</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {loading ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (
            seasons.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((season) => (
              <TableRow key={season.id}>
                  {/*<TableCell><Button onClick={() => (setSelectedSeason(season), history(`/?season=${season.id}`))}>CS</Button></TableCell>*/}
                  <TableCell><Link to={`season/${season.id}`}>{season.id} Go to Season </Link> </TableCell>
                <TableCell>{season.title} – {season.year_season.years}</TableCell>
                <TableCell> <img src={season.competition_logo} alt={season.short_name} height={20} /></TableCell>
                <TableCell> <img src={season.country_flag} alt={season.short_name} height={20} /></TableCell>
              </TableRow>
            )))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={seasons.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
        {selectedSeason && (
        <div style={{marginTop: 25}}>
          <img src={selectedSeason.competition_logo} alt="Competition Logo" height={20} />
          <img src={selectedSeason.country_flag} alt="Country Flag" height={20}/>
          <h2>{selectedSeason.title} – {selectedSeason.year_season.years}</h2>
        </div>
      )}
        {/*<div>*/}
        {/*   {*/}
        {/*        matches == false ? " "*/}
        {/*            :*/}
        {/*            <div>*/}
        {/*                <TableContainer component={Paper}>*/}
        {/*  <Table>*/}
        {/*    <TableHead>*/}
        {/*      <TableRow>*/}
        {/*        <TableCell>ID</TableCell>*/}
        {/*      </TableRow>*/}
        {/*    </TableHead>*/}
        {/*    <TableBody>*/}
        {/*      {matches === undefined ? '' : matches.map((match) => (*/}
        {/*        <TableRow key={match.id}>*/}

        {/*          <TableCell>*/}
        {/*            <Link to={`/match/${match.id}`}>*/}
        {/*              {match.id}*/}
        {/*            </Link>*/}
        {/*          </TableCell>*/}
        {/*        </TableRow>*/}
        {/*      ))}*/}
        {/*    </TableBody>*/}
        {/*  </Table>*/}
        {/*  <TablePagination*/}
        {/*    component="div"*/}
        {/*    count={1000}*/}
        {/*    page={mPage - 1}*/}
        {/*    rowsPerPage={10}*/}
        {/*    onPageChange={handleMChangePage}*/}
        {/*  />*/}
        {/*</TableContainer>*/}
        {/*            </div>*/}
        {/*    }*/}
        {/*</div>*/}
    </div>
  );
//   const [seasonId, setSeasonId] = useState('');
//   const [teams, setTeams] = useState([]);
//   const [matches, setMatches] = useState([]);
//   const [selectedTeamFirst, setSelectedTeamFirst] = useState(null);
//   const [selectedTeamSecond, setSelectedTeamSecond] = useState(null);
//   const [selectedTeams, setSelectedTeams] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredMatches, setFilteredMatches] = useState([]);
//   const [answer, setAnswer] = useState(false)
//     const [home_roster_found, setHomeRosterFound] = useState([])
//     const [away_roster_found, setAwayRosterFound] = useState([])
//     const [homeRoster, setHomeRoster] = useState([])
//     const [message, setMessage] = useState()
//     const [rosterFound, setRosterFound] = useState([])
//
//   useEffect(() => {
//     if (seasonId) {
//       Promise.all([fetchTeams(seasonId), fetchMatches(seasonId, currentPage)])
//         .then(([teamsData, matchesData]) => {
//           setTeams(teamsData);
//           setMatches(matchesData.matches);
//           setTotalPages(matchesData.totalPages);
//           setFilteredMatches(matchesData.matches);
//         })
//         .catch((error) => {
//           console.error('Error:', error);
//         });
//     }
//   }, [seasonId, currentPage]);
//
//   const fetchTeams = (seasonId) => {
//     return fetch(base_url + 'season/' + seasonId)
//       .then((response) => response.json())
//       .then((data) => {
//         const teamsData = data.teams;
//         return teamsData;
//       });
//   };
//
//   const fetchMatches = (seasonId, page) => {
//     return fetch(base_url + 'match/?season=' + seasonId + '&page=' + page)
//       .then((response) => response.json())
//       .then((data) => {
//         const matchesData = data.results;
//         const totalPagesData = Math.ceil(data.count / 10);
//         return { matches: matchesData, totalPages: totalPagesData };
//       });
//   };
//
//   console.log(matches)
//

//
//

//     async function getSquads(isHome) {
//       const teamName = isHome ? selectedTeamFirst.name : selectedTeamSecond.name;
//       const response = await fetch(base_url + 'match/?search=&search=' + teamName);
//       const data = await response.json();
//       const filteredData = data.results.filter(item => (isHome ? item.home_team === teamName : item.away_team === teamName));
//       const sortedData = filteredData.sort((a, b) => b.id - a.id);
//       const result = sortedData.length > 0 ? [sortedData] : [];
//
//       const rosterFound = isHome ? home_roster_found : away_roster_found;
//       const squadField = isHome ? 'home_squad' : 'away_squad';
//
//         for (const item of result[0]) {
//         const id = item[squadField];
//         try {
//           const response = await fetch(base_url + 'squad/' + id);
//           const data = await response.json();
//           if (data.roster.length > 0) {
//               console.log(data.roster)
//             setRosterFound(prevState => [...prevState, data.roster]);
//           } else {
//             console.log('Found null roster:', data.roster);
//           }
//         } catch (error) {
//           console.error('Error:', error);
//         }
//       }
//         console.log(rosterFound)
//       console.log(Array.from(new Set(rosterFound.flatMap(array => array.map(item => item.id)))))
//   return Array.from(new Set(rosterFound.flatMap(array => array.map(item => item.id))));
// }
//
//   const handleSeasonIdChange = (event) => {
//     setSeasonId(event.target.value);
//   };
//
//   const handleTeamClickFirst = (team) => {
//     setSelectedTeamFirst(team);
//     setSelectedTeams([]);
//   };
//
//   const handleTeamClickSecond = (team) => {
//     setSelectedTeamSecond(team);
//     setSelectedTeams([{ first: selectedTeamFirst, second: team }]);
//   };
//
//   const handlePaginationChange = (event, value) => {
//     setCurrentPage(value);
//   };
//
//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };
//
//   useEffect(() => {
//     if (searchTerm) {
//       const filtered = matches.filter(
//         (match) =>
//           match.home_team.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           match.away_team.toLowerCase().includes(searchTerm.toLowerCase())
//       );
//       setFilteredMatches(filtered);
//     } else {
//       setFilteredMatches(matches);
//     }
//   }, [searchTerm, matches]);
//
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     fetchTeamsAndMatches(seasonId);
//   };
//
//   const fetchTeamsAndMatches = (seasonId) => {
//     Promise.all([fetchTeams(seasonId), fetchMatches(seasonId, currentPage)])
//       .then(([teamsData, matchesData]) => {
//         setTeams(teamsData);
//         setMatches(matchesData.matches);
//         setTotalPages(matchesData.totalPages);
//         setFilteredMatches(matchesData.matches);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   };
//
//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <TextField
//           label="Season ID"
//           value={seasonId}
//           onChange={handleSeasonIdChange}
//         />
//         <Button type="submit" variant="contained" color="primary">
//           Submit
//         </Button>
//       </form>
//         {/*<Button onClick={() => findSquads()}>*/}
//         {/*    Find squads*/}
//         {/*</Button>*/}
//
//       <Grid container spacing={2} style={{ marginTop: 20 }}>
//         <Grid item xs={4}>
//           <div style={{ maxHeight: '300px', overflow: 'auto' }}>
//             <List>
//               {
//                   seasonId != false && teams == false ?
//                       <div>
//                         <p>
//                             Teams in the draw of this tournament
//                             </p>
//                           <p>
//                         You can enter them through
//                             <Link to={`/season/${seasonId}`}> this section </Link>
//                         </p>
//                       </div>
//                   :
//                   teams.map((team) => (
//                 <div
//                   key={team.id}
//                   style={{
//                     fontWeight: selectedTeamFirst === team ? 'bold' : 'normal',
//                     cursor: 'pointer',
//                     marginBottom: '8px',
//                     paddingLeft: '20px',
//                   }}
//                   onClick={() => handleTeamClickFirst(team)}
//                 >
//                   <p>
//                      {team.id}. {team.name}
//                   </p>
//                 </div>
//               ))}
//             </List>
//           </div>
//         </Grid>
//         <Grid item xs={4}>
//           <div style={{ maxHeight: '300px', overflow: 'auto' }}>
//             {teams
//       .filter((team) => team !== selectedTeamFirst)
//       .map((team) => (
//         <div
//           key={team.id}
//           style={{
//             fontWeight: selectedTeamSecond === team ? 'bold' : 'normal',
//             cursor: 'pointer',
//             marginBottom: '8px',
//             paddingLeft: '20px',
//           }}
//           onClick={() => handleTeamClickSecond(team)}
//         >
//           <p>
//             {team.id}. {team.name}
//           </p>
//         </div>
//       ))}
//           </div>
//         </Grid>
//         <Grid item xs={4}>
//           {selectedTeams.length > 0 ? (
//             <div>
//               <TextField type="date" onChange={e => setSelectedDate(e.target.value)}/>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 disabled={!selectedDate}
//                 style={{ marginBottom: 10 }}
//                 onClick={() => createMatch()}
//               >
//                 Create Match
//               </Button>
//               {/* Display selected teams and date */}
//               <Typography variant="h6">Selected Teams:</Typography>
//               <List>
//                 {selectedTeams.map((team, index) => (
//                   <ListItem key={index}>
//                     <ListItemText
//                       primary={`First Team: ${team.first.name}`}
//                       secondary={`ID: ${team.first.id}`}
//                     />
//                     <ListItemText
//                       primary={`Second Team: ${team.second.name}`}
//                       secondary={`ID: ${team.second.id}`}
//                     />
//                   </ListItem>
//                 ))}
//               </List>
//               <Typography variant="h6" style={{ marginTop: 20 }}>
//                 Selected Date: {selectedDate && selectedDate}
//               </Typography>
//                  {
//                                 answer !== false ?
//                                     <Alerts success={answer} type={'Match ' + selectedTeamFirst.name + ' & ' + selectedTeamSecond.name  } message={message} action={'added'}/>
//                                     :
//                                     <div></div>
//                             }
//             </div>
//           ) : (
//             <Typography variant="body2">No teams selected</Typography>
//           )}
//         </Grid>
//       </Grid>
//         {
//             matches == false ? "  " :
//
//          <Grid item xs={12} style={{ marginTop: 50 }}>
//
//
//           <Grid item xs={12}>
//                 <Grid container spacing={2}>
//                 <TextField
//                     label="Search by Team Name"
//                     value={searchTerm}
//                     onChange={handleSearchChange}
//                     style={{ marginTop: 20 }}
//                 />
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>ID</TableCell>
//                     <TableCell>Team 1</TableCell>
//                     <TableCell>Team 2</TableCell>
//                     <TableCell>Date</TableCell>
//                     <TableCell>Video</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                         {
//                             filteredMatches === undefined ? " " :
//
//                             filteredMatches.map((match) => (
//                           <TableRow key={match.id}>
//                             <TableCell>{match.id}</TableCell>
//                             <TableCell>
//                               <Link to={`/match/${match.id}`}>{match.home_team}</Link>
//                             </TableCell>
//                             <TableCell>{match.away_team}</TableCell>
//                             <TableCell>{match.date}</TableCell>
//                             <TableCell>{match.video === false ? " no match video " : 'match has video'}</TableCell>
//                           </TableRow>
//                         ))}
//       </TableBody>
//               </Table>
//             </TableContainer>
//           </Grid>
//           <Grid item xs={12}>
//             <Pagination
//               count={totalPages}
//               page={currentPage}
//               onChange={handlePaginationChange}
//             />
//           </Grid>
//         </Grid>
//
//       </Grid>
//         }
//     </div>
//   );
}


export default Matches;